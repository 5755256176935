<template>
    <swiper 
        ref="swiper"
        :options="swiperOptions" 
        class="product-swiper"
        :breakpoints="breakpoints"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
    >
        <swiper-slide 
            v-for="(product, i) in products" 
            :key="i"
            class="swiper-slide-custom"
        >
            <product-grid-card 
                :product="product" 
                @open-dialog="$emit('open-dialog', product)" 
            />
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper-vue2'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import ProductGridCard from "../cards/ProductGridCard"
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Navigation, Pagination])

export default {
    name: 'ProductSwiper',
    components: {
        Swiper,
        SwiperSlide,
        ProductGridCard
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    methods: {
        onSwiper(swiper) {
            this.swiperInstance = swiper;
            this.onSwiperStateChange(swiper);
        },
        onSlideChange() {
            if (this.swiperInstance) {
                this.onSwiperStateChange(this.swiperInstance);
            }
        },
        onSwiperStateChange(swiper) {
            const state = {
                isBeginning: swiper.isBeginning,
                isEnd: swiper.isEnd,
                activeIndex: swiper.activeIndex,
                slides: swiper.slides,
                length: swiper.slides.length
            };
            this.$emit('state-change', state);
        },
        slideNext() {
            if (this.swiperInstance) {
                this.swiperInstance.slideNext();
            }
        },
        slidePrev() {
            if (this.swiperInstance) {
                this.swiperInstance.slidePrev();
            }
        }
    },
    mounted() {
        // Removendo o código do mounted pois vamos usar o evento @swiper
    },
    data() {
        return {
            swiperOptions: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                initialSlide: 0,
                slidesPerView: 'auto',
                spaceBetween: 5,
                touchEventsTarget: 'container',
                preventInteractionOnTransition: true,
                watchOverflow: true,
                observer: true,
                observeParents: true,
                slidesPerGroup: 4,
                speed: 400,
                effect: 'slide',
                setWrapperSize: true,
                watchSlidesProgress: true,
                slidesPerGroupAuto: true,
                loopFillGroupWithBlank: false,
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                    slidesPerGroup: 2,
                    width: null,
                    effect: 'slide',
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                    slidesPerGroup: 2,
                    width: null,
                    effect: 'slide',
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                    width: null,
                },
                1024: {
                    slidesPerView: 4.5,
                    spaceBetween: 12,
                    slidesPerGroup: 4,
                    width: null,
                    grid: {
                        fill: 'row',
                        rows: 1
                    }
                }
            },
            swiperInstance: null
        }
    }
}
</script>

<style lang="scss" scoped>
.product-swiper {
    width: 100%;
    position: relative;
    margin-left: -50px;
    padding-left: 10px;
    
    :deep(.swiper-wrapper) {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
    }
    
    :deep(.swiper-slide-custom) {
        height: auto !important;
        width: auto !important;
        display: flex !important;
        align-items: stretch !important;
        transform: none !important;
        transition: none !important;
        padding: 0 !important;
        margin: 0 !important;
        flex: 0 0 auto !important;
    }

    :deep(.swiper-button-prev),
    :deep(.swiper-button-next) {
        color: #000;
        
        &::after {
            font-size: 24px;
        }

        &.swiper-button-disabled {
            opacity: 0.35;
            cursor: not-allowed;
            pointer-events: none;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    @media (max-width: 768px) {
        margin: 0;
        padding: 0 16px;
        width: 100%;
        overflow: hidden;
        
        :deep(.swiper-wrapper) {
            display: flex !important;
            justify-content: flex-start !important;
            gap: 16px !important;
            align-items: stretch !important;
        }

        :deep(.swiper-slide-custom) {
            width: calc((100% - 16px) / 2) !important;
            flex: 0 0 auto !important;
            height: auto !important;
            transform: none !important;
            transition: none !important;
            margin: 0 !important;
        }
    }

    @media (min-width: 1024px) {
        :deep(.swiper-wrapper) {
            justify-content: flex-start;
            gap: 12px;
        }

        :deep(.swiper-slide-custom) {
            width: 265px !important;
            margin-right: 0 !important;
        }

        &:has(.swiper-wrapper:not(:has(.swiper-slide-custom:nth-child(4)))) {
            :deep(.swiper-wrapper) {
                justify-content: center;
            }
        }
    }
}

:deep(.product-grid-card) {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

:deep(.swiper-slide-active),
:deep(.swiper-slide) {
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
    margin: 0 !important;
}
</style> 