var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper',{staticClass:"mySwiper",attrs:{"slides-per-view":1,"space-between":100,"centeredSlides":true,"slideToClickedSlide":true,"breakpoints":{
    '@0.00': {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    '@0.75': {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    '@1.00': {
      slidesPerView: 5,
      spaceBetween: 40,
    },
  }}},_vm._l((_vm.products),function(product,i){return _c('swiper-slide',{key:i,staticStyle:{"border-radius":"15px","overflow":"hidden"}},[_c('img',{attrs:{"src":product.imagem,"lazy-src":"@/assets/product-image-placeholder.png"},on:{"error":function($event){$event.target.src=require(`@/assets/product-image-placeholder.png`)}}}),_c('div',{staticClass:"slide-overlay",on:{"click":function($event){return _vm.openProductDialog(product)}}},[_c('div',{staticClass:"ml-3 mb-2 d-flex flex-column align-start",staticStyle:{"width":"calc(100% - 26px)"}},[(product.free_rate_quantity)?_c('div',{staticClass:"ribbon ribbon-top-left ribbon-blue"},[_c('span',[_vm._v("Frete grátis")])]):_vm._e(),(product.has_promotions)?_c('v-chip',{staticClass:"promotion ma-2",staticStyle:{"border-radius":"10px !important"},attrs:{"color":"green","x-small":""}},[_vm._v(" PROMOÇÃO ")]):_vm._e(),_c('span',{staticClass:"text-left"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[((product.promotions[0] ?? null) && product.promotions[0].compositions.length == 0)?_c('div',{staticClass:"text-left",staticStyle:{"width":"100%"}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("De: "),_c('s',[_vm._v(_vm._s(_vm.formatMoney(_vm.getProductPrice(product))))])]),_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('span',[_vm._v("Por: "+_vm._s(_vm.formatMoney(_vm.getProductPrice(product) - _vm.getPromotionDiscount(product.promotions[0], product.price))))]),_c('span',{staticStyle:{"margin-top":"3px","font-weight":"normal","font-size":"9px","color":"#ccc"}},[_vm._v(" Ver mais ")])])]):_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.getProductPrice(product)))+" ")]),_c('span',{staticStyle:{"margin-top":"3px","font-weight":"normal","font-size":"9px","color":"#ccc"}},[_vm._v(" Ver mais ")])])])],1)])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }