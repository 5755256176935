<template>
  <div>
    <div class="py-0 pt-0 d-flex justify-center text-center">
      <v-container class="pa-0" style="position: relative;">
        <div class="banner-cover" :style="`${theme?.cover ? 'background: url(' + theme?.cover + ');' : ''}`"></div>
        <div
          style="background: linear-gradient(359.85deg, #000000 -60%, rgba(0, 0, 0, 0)); height: 100%; width: 100%; top: 0; position: absolute;">
        </div>
        <v-chip
          style="position: absolute; top: 0; right: 0;"
          class="ma-3"
          :color="theme?.closed ? 'warning' : 'success'"
          @click="openSchedule"
          small
        >
          <v-icon left size="18">
            mdi-storefront-outline
          </v-icon>
          {{ theme?.closed ? "Fechado" : "Aberto" }}
        </v-chip>
      </v-container>
    </div>

    <v-container>
      <div class="d-flex company-info">
        <div class="logo-box">
          <v-img :src="theme.logo" lazy-src="https://picsum.photos/id/11/100/6" class="logo">
          </v-img>
        </div>
        <company-name :theme="theme" :show="true"></company-name>
        <div class="see-more gray--text text--lighten-5 ml-2" @click="openSchedules">
          Ver mais
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="d-flex align-center align-start" v-if="theme.free_rate_from && theme.free_rate_from > 0">
        <div><v-icon class="mr-2">mdi-motorbike</v-icon></div>
        <div>
          <span class="font-weight-bold"> Frete grátis a partir de</span>
          <span class="font-weight-bold primary--text"> R${{ theme.free_rate_from / 100 }}</span>
        </div>
      </div>
      <schedule v-model="openSchedule" :closed="theme?.closed" :schedule="theme?.schedule">
      </schedule>
      <div class="d-flex justify-center">
        <cashback-rules-dialog class="mb-5"
          v-if="theme?.active_cashback && theme?.cashback_settings?.cashback_by_total_amount && theme?.cashback_settings?.cashback_rules"
          :cashbackRules="theme?.cashback_settings?.cashback_rules" />
      </div>
      <v-text-field label="Pesquisar" ref="searchField" class="mt-2" append-icon="mdi-magnify" v-model="search" @input="searchProducts"
        dense single-line rounded outlined hint="Digite o nome do produto que procura" />
      <div class="p-list">
        <div 
          v-infinite-scroll="loadMoreProduct" infinite-scroll-disabled="busy"
          :infinite-scroll-distance="products.data.length * 100"
        >
          <div class="white product-list" v-for="(products, category_name) in groupedProduct" :key="category_name">
            <product-list :products="products" :theme="theme" :category="category_name" />
          </div>
        </div>
        <v-layout v-if="loading" class="text-center mt-5">
          <product-card-skeleton :theme="theme" />
        </v-layout>
        <div v-if="!loading && products.data.length == 0" class="text-center">
          <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
          <span class="font-weight-bold">Nenhum resultado encontrado</span>
        </div>
      </div>
    </v-container>
    <div class="p-list-mobile">
      <div 
        v-infinite-scroll="loadMoreProduct" infinite-scroll-disabled="busy"
        :infinite-scroll-distance="products.data.length * 100"
      >
        <div class="white product-list" v-for="(products, category_name) in groupedProduct" :key="category_name">
          <product-list :products="products" :theme="theme" :category="category_name" />
        </div>
      </div>
      <v-layout v-if="loading" class="text-center mt-5">
        <product-card-skeleton :theme="theme" />
      </v-layout>
      <div v-if="!loading && products.data.length == 0" class="text-center">
        <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
        <span class="font-weight-bold">Nenhum resultado encontrado</span>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "../components/inputs/TextField";
import ChipSkeleton from "../components/skeletons/Chips";
import Schedule from "../components/Schedule";
import CompanyName from "../components/CompanyName";
import ProductCardSkeleton from "../components/skeletons/ProductCard";
import HighlightsSkeleton from "../components/skeletons/Highlights";
import CashbackRulesDialog from "../components/modals/CashbackRulesDialog";
import ProductList from "../components/ProductList";
import Highlights from "../components/slides/Highlights";
import _debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",

  components: {
    TextField,
    CompanyName,
    ProductList,
    ChipSkeleton,
    ProductCardSkeleton,
    Schedule,
    CashbackRulesDialog,
    HighlightsSkeleton,
    Highlights,
  },

  async created() {
    if (this.categories.length == 0) {
      this.getCategories({});
    }

    if (this.highlights.length == 0) {
      this.getHighlights({});
    }

    if (this.products.data.length == 0) {
      this.getProducts({});
    }

    this.requestPermission();
  },

  data() {
    return {
      search: "",
      busy: false,
      scheduleDialog: false,
      selectedCategory: "",
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.searchField.focus();
    });
  },

  computed: {
    ...mapState({
      openSchedule: (state) => state.theme.openSchedule,
      theme: (state) => state.theme.theme,
      categories: (state) => state.categories.categories,
      categoriesLoading: (state) => state.categories.loading,
      products: (state) => state.products.products,
      highlights: (state) => state.products.highlights,
      loading: (state) => state.products.loading,
      highlightsLoading: (state) => state.products.highlightsLoading,
    }),
    groupedProduct() {
      return this.products.data.reduce((group, product) => {
        let { category_name } = product;
        group[category_name] = group[category_name] ?? [];
        group[category_name].push(product);
        return group;
      }, {});
    },
  },

  methods: {
    ...mapActions(["getProducts", "getCategories", "getHighlights"]),

    searchProducts: _debounce(async function () {
      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
      });
    }, 600),
    // showNotificationPermissionRequest() {
    //   if (Notification.permission === 'default') {
    //     var options = {
    //       body: 'Seja bem-vindo ao <nome do site>! Para aprimorar sua experiência e receber notificações de promoções e descontos, clique no botão "Eu quero".',
    //       icon: 'caminho/para/icone.png',
    //       requireInteraction: true,
    //       actions: [
    //         { action: 'confirmar', title: 'Eu quero' }
    //       ]
    //     };

    //     var notification = new Notification('Permissão de Notificação', options);

    //     notification.addEventListener('notificationclick', function(event) {
    //       if (event.action === 'confirmar') {

    //       }

    //       // notification.close();
    //     });
    //   }
    // },
    requestPermission() {
      if ("Notification" in window && navigator.serviceWorker) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // this.showNotificationPermissionRequest();
          } else if (permission === "denied") {
          } else {
          }
        });
      }
    },

    async loadMoreProduct() {
      let cantLoadMore =
        this?.products.data.length >= this?.products?.meta?.total;

      if (this.busy || this.loading || cantLoadMore) return;

      this.busy = true;

      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
        page: this?.products?.meta?.current_page + 1,
      });

      this.busy = false;
    },

    async setCategory(category) {
      this.selectedCategory =
        this.selectedCategory?.id === category.id ? "" : category;

      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
      });
    },
    openSchedules() {
      this.$store.commit('setOpenSchedule', {
        openSchedule: true
      });
    },
  },
};
</script>

<style>
.banner-cover {
  width: 100%;
  min-height: 150px;
  background-size: cover !important;
  background-color: var(--v-secondary);
}

.company-info {
  margin-top: -44px;
  position: relative;
}

.logo-box {
  position: relative;
  min-width: 60px !important;
  max-width: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  border-radius: 100%;
  border: 2px solid var(--v-primary);
  box-shadow: 0px 0px 3px rgb(0, 0, 0);
  overflow: hidden;
}

.see-more {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
  min-width: 60px !important;
}

.logo {
  object-fit: cover;
  object-position: center;
  height: 100px;
  width: 100px
}

.logo-position {
  transform: translate(15px, 85px);
  width: 90%;
}

.product-list {
  overflow: hidden;
  margin-top: 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.p-list {
  display: none;
}

.p-list-mobile {
  display: block;
}

@media (min-width: 768px) {
  .p-list {
    display: block;
  }

  .p-list-mobile {
    display: none;
  }
  
  .company-info {
    margin-top: -57px;
    position: relative;
  }

  .product-list {
    border-radius: 10px;
  }

  .logo-box {
    min-width: 90px !important;
    max-width: 90px !important;
    min-height: 90px !important;
    max-height: 90px !important;
  }

  .see-more {
    margin-top: 18px;
  }

  .banner-cover {
    min-height: 250px;
    border-radius: 0px 0px 20px 20px;
  }

  .page-top-margin {
    margin-top: 60px !important;
  }

  .logo-position {
    transform: translate(25px, 80px);
  }

  .company-description-without-background {
    margin-top: -5px;
    margin-left: 97px;
  }
}

.banner {
  padding-top: 20px;
  background: #fff;
  height: 100px;
  border-radius: 300% 300% 0px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
