<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      max-width="1000"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-end mt-1"
          style="border-radius: 0 0 15px 15px !important"
          v-bind="attrs"
          v-on="on"
        >
          <v-btn color="#fff" block small> Ver detalhes </v-btn>
        </div>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ product.name }}</v-toolbar-title>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-list
          subheader
        >
          <div v-for="(items, type) in product.compositions" :key="type">
            <v-subheader>{{ type }}</v-subheader>
            <v-list-item>
                <v-list-item-content>
                    <div v-if="(items instanceof Array)">
                        <v-list-item-title  v-for="(option, key) in items" :key="key">
                            -- {{ option?.name ?? option }}
                            <span v-if="option.additional_price > 0 && !option?.use_max_value_as_price">{{ `(+ ${formatMoney(option.additional_price)})` }}</span>
                        </v-list-item-title>
                    </div>
                    <div v-else>
                        <v-list-item-title>
                            -- {{ items?.name ?? items }}
                            <span v-if="items.additional_price > 0">{{ `(+ ${formatMoney(items.additional_price)})` }}</span>
                        </v-list-item-title>
                    </div>
                </v-list-item-content>
            </v-list-item>
          </div>
          <div>
            <v-textarea
              outlined
              id="observation"
              class="mx-3 mt-5"
              name="input-7-4"
              :value="product.observations"
              @input="update('observations', $event)"
              color="primary"
              label="Observações"
            ></v-textarea>
          </div>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "bag-product-detail",
  props: ["product"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    update(key, value) {
      let productId = this.product.id;

      this.$store.commit("changeProductObservationFromBag", {
        productId,
        observations: value
      });
    },
  }
};
</script>