<template>
  <div class="ml-2 text-position" style="position: relative;">
    <div class="site-name text-uppercase gray--text text--lighten-5">
      <span>{{ theme.siteName }}</span>
    </div>
    <div class="gray--text text-capitalize site-description mt-2">
      <span>{{ theme.siteDescription }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "company-name",
  props: ["theme", "show"],
};
</script>

<style>
.text-position {
  margin-top: 5px;
  flex: 1;
  min-width: 0;
  overflow: hidden;
}
.site-name {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 0.9rem;
}
@media (min-width: 768px) {
  .text-position {
    margin-top: 20px;
  }
}
</style>