<template>
    <v-card tile class="card-dialog" style="height: 100%;" elevation="sm">
        <div class="dialog-header" style="box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);">
            <span class="pa-4" @click="closeProductModal"><v-icon color="#ccc"
                    size="34">mdi-chevron-left</v-icon></span>
            <span class="title-header">{{ product?.name }}</span>
        </div>
        <div class="dialog-image">
            <custom-carousel :images="product?.images?.length > 0 ? product?.images : [product?.imagem]" :hideDelimiters="true" />
        </div>
        <div class="dialog-body" style="background-color: #fff !important; position: relative; width: 100%; display: flex; flex-direction: column;">
            <div class="dialog-description flex-grow-1">
                <v-card class="darken-1 rounded-0" height="100%" style="box-shadow: none;">
                    <promotions-dialog v-if="product?.has_promotions" :promotions="product?.promotions"
                        :theme="theme" v-model="promotionsDialog" />
                </v-card>
                <v-row class="mb-2" no-gutters style="flex: 0 !important;">
                    <v-col
                        v-if="product?.free_rate_quantity && product?.measure_unit !== 'kg'"
                        style="font-size: 12px;"
                        cols="12"
                        class="py-2 text-center white--text text-capitalize info"
                    >
                        Na compra de
                        <span class="font-weight-bold">{{ product?.free_rate_quantity }} unidades</span>
                        deste produto, o <span class="font-weight-bold">frete é grátis</span>
                    </v-col>
        
                    <v-col v-if="product?.use_promotional_quantity && product?.promotional_price" style="font-size: 12px;"
                        cols="8" class="gray--text text-darken-2 text-capitalize">
                        <v-card class="pa-2 green lighten-2 d-flex align-center" height="100%" outlined tile>
                            <span v-if="product?.measure_unit === 'un'">
                                *Ao comprar a partir de
                                <span class="font-weight-bold">{{ parseInt(product?.promotional_quantity) }}
                                    unidades</span><br />
                                o preço unitário fica <span class="font-weight-bold">{{
                                    formatMoney(product?.promotional_price) }}</span>
                            </span>
                            <span v-else>
                                *Ao comprar a partir de
                                <span class="font-weight-bold">{{ product?.promotional_quantity }} kg</span> o
                                preço kilograma fica
                                <span class="font-weight-bold">{{ formatMoney(product?.promotional_price) }}</span>
                            </span>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                    </v-col>
                </v-row>
                <div class="d-flex flex-column justify-space-between align-start px-lg-0">
                    <div class="text-left">
                        <div class="product-name font-weight-bold" style="line-height: 1.2rem;">
                            {{ product?.name }}
                        </div>
                        <div class="mt-1" style="font-size: 0.7rem;">
                            {{ product?.tags ? product?.tags.join(', ') : '' }}
                        </div>
                    </div>
                    <div v-if="product?.preparation_time_label" class="d-flex justify-space-around mt-7" style="width: 100%;">
                        <div class="d-flex flex-column align-center text-center" v-if="product?.price > 0">
                            <span class="font-weight-bold">Preço</span>
                            <span class="title font-weight-bold primary--text">{{ formatMoney(total) }}</span>
                        </div>
                        <div style="border-right: 1px solid #00c89e"></div>
                        <div class="d-flex flex-column align-center text-center">
                            <span class="font-weight-bold">Tempo estimado</span>
                            <span class="title font-weight-bold primary--text">{{
                                product?.preparation_time_label }}</span>
                        </div>
                    </div>
                    <div v-else class="d-flex mt-4">
                        <div class="d-flex flex-column align-start" v-if="product?.price > 0">
                            <div
                                v-if="(product?.promotions[0] ?? null) && product?.promotions[0].compositions.length == 0">
                                <v-chip v-if="product?.promotions[0].discount_type !== 'fixed'" class="mr-1"
                                    color="success" x-small>
                                    -{{ product?.promotions[0].discount_value / 100 }}%
                                </v-chip>
                                <span style="font-size: 14px;">De: <s>{{ formatMoney(getProductPrice(product)) }}</s></span><br />
                                <span
                                    :class="`title font-weight-bold ${product?.use_promotional_quantity && product?.promotional_quantity && quantity >= product?.promotional_quantity ? 'success--text' : 'primary--text'}`">Por:
                                    {{ formatMoney(getProductPrice(product) -
                                        getPromotionDiscount(product?.promotions[0], product?.price)) }}</span>
                            </div>
                            <div v-else>
                                <span
                                    :class="`title font-weight-bold ${product?.use_promotional_quantity && product?.promotional_quantity && quantity >= product?.promotional_quantity ? 'success--text' : 'primary--text'}`">{{
                                    formatMoney(total) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="composition px-lg-0 overflow-auto">
                <composition 
                    v-show="product?.compositions?.length > 0"
                    :compositions="product?.compositions"
                    :promotions="product?.promotions"
                    :model.sync="composition"
                />
                <v-layout class="mt-2 px-3 px-md-0">
                    <v-textarea outlined rows="3" id="observation" name="input-7-4" v-model="observations" color="primary" label="Observações"></v-textarea>
                </v-layout>
            </div>
            <div class="to-cart-container">
                <div class="d-flex align-center justify-space-between px-3 px-md-0" style="width: 100%;">
                    <div v-if="product?.measure_unit === 'kg'">
                        <v-text-field v-model="quantity" label="Quantidade" class="mr-4" style="width: 170px;" ref="kilo" type="number"
                            min="1" :max="product?.stock_logic ? product?.stock : null"
                            oninput="if(this.max && Number(this.value) > Number(this.max)) this.value = this.max;"
                            dense hide-details suffix='kg' outlined></v-text-field>
                    </div>
                    <div v-else class="d-flex align-center px-0 mr-4" style="border: 1px solid #ccc; border-radius: 5px">
                        <v-btn :disabled="quantity <= 1" outlined plain class="transparent" style="padding: 0px !important;"
                            color="primary" @click="subQuantity">
                            <v-icon size="26">mdi-minus</v-icon>
                        </v-btn>
                        <span style="font-size: 22px; width: 40px;" class="gray--text text-center">
                            {{ quantity }}
                        </span>
                        <v-btn :disabled="product?.stock_logic && quantity >= product?.stock ? true : false"
                            color="primary" class="transparent" style="padding: 0px !important;" outlined plain @click="addQuantity">
                            <v-icon size="26">mdi-plus</v-icon>
                        </v-btn>
                    </div>
                    <v-btn id="add-to-cart" class="notranslate" color="primary" translate="no"
                        :disabled="this.hasSelectedAllCompositions || quantity <= 0" @click="addToBag(composition, observations)">
                        Adicionar
                        <span class="">{{ formatMoney(totalPrice * quantity) }}</span>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import CustomCarousel from '@/components/slides/Carousel.vue';
import PromotionsDialog from '@/components/modals/PromotionsDialog.vue';
import Composition from '@/components/Composition.vue';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'ProductDialog',
    props: ['product'],

    components: {
        CustomCarousel,
        PromotionsDialog,
        Composition
    },
    data() {
        return {
            promotionsDialog: false,
            quantity: 1,
            productId: this.$route.params.id,
            promotionsDialog: false,
            limitExceeded: false,
            observations: "",
            composition: {},
            kilogram: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: ' #',
                precision: 2,
                masked: false /* doesn't work with directive */
            }
        }
    },
    async created() {
        if (this.product?.stock_logic && this.product?.stock <= 0) {
            this.$router.replace('/');
        }

        if (this.product?.id != this.productId) {
            await this.findProduct({
                productId: this.productId
            });
        }
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme.theme,
            loading: state => state.products.loading
        }),
        hasSelectedAllCompositions() {
            if (!this.product?.compositions) return false;

            let selectedComposition = this.composition;
            let blocked = false;

            this.product?.compositions.forEach(function (composition) {
                let totalSelected = selectedComposition[composition.name] ? selectedComposition[composition.name]?.length ?? 1 : 0;

                if (
                    composition?.min_selection &&
                    totalSelected < composition.min_selection
                ) {
                    blocked = true;
                }
            });

            return blocked;
        },
        totaPriceWithoutPromotion() {
            let total = this.product?.price;
            let selectedValues = [];
            let selectedValue = null;
            let compositionWithMaxValue = this?.product?.compositions?.filter((item) => item.use_max_value_as_price);

            for (let propName in this.composition) {
                let obj = this.composition[propName];
                let isCompositionWithMaxValue = (compositionWithMaxValue ?? [])?.find((item) => item.name == propName);

                if (obj?.constructor?.name === 'Array') {
                    total += obj.reduce(function (acc, comp) {
                        selectedValue = parseInt(comp.additional_price ?? 0);
                        selectedValues.push(selectedValue);
                        return acc + selectedValue;
                    }, 0);
                } else {
                    selectedValue = parseInt(obj?.additional_price ?? 0);
                    selectedValues.push(selectedValue);
                    total += selectedValue;
                }

                if (isCompositionWithMaxValue) {
                    total = Math.max(...selectedValues);
                }
            }

            return total;
        },
        total() {
            let activePromotion = this.hasActivePromotion;

            if (activePromotion) {
                let price = 0;

                if (activePromotion.discount_type == 'final_price') {
                    price = activePromotion.discount_value;
                    return price;
                } else {
                    // price = this.getPromotionDiscount(activePromotion, this.product?.price);
                }
            }

            if (this.product?.use_promotional_quantity && this.product?.promotional_quantity && this.quantity >= this.product?.promotional_quantity) {
                return this.product?.promotional_price;
            }

            return this.product?.price;
        },
        totalPrice() {
            let total = this.total;
            let selectedValues = [];
            let selectedValue = null;
            let compositionWithMaxValue = this?.product?.compositions?.filter((item) => item.use_max_value_as_price);

            for (let propName in this.composition) {
                let obj = this.composition[propName];
                let isCompositionWithMaxValue = (compositionWithMaxValue ?? [])?.find((item) => item.name == propName);

                if (obj?.constructor?.name === 'Array') {
                    total += obj.reduce(function (acc, comp) {
                        selectedValue = parseInt(comp.additional_price ?? 0);
                        selectedValues.push(selectedValue);
                        return acc + selectedValue;
                    }, 0);
                } else {
                    selectedValue = parseInt(obj?.additional_price ?? 0);
                    selectedValues.push(selectedValue);
                    total += selectedValue;
                }

                if (isCompositionWithMaxValue) {
                    total = Math.max(...selectedValues);
                }
            }

            let activePromotion = this.hasActivePromotion;

            if (activePromotion) {
                if (activePromotion.discount_type == 'final_price') {
                    total = activePromotion.discount_value;
                } else {
                    total -= this.getPromotionDiscount(activePromotion, total);
                }
            }

            return total;
        },
        hasActivePromotion() {
            let activePromotion = false;

            for (let index in this.product?.promotions) {
                let promotion = this.product?.promotions[index];
                activePromotion = promotion;

                promotion.compositions.forEach((composition) => {
                    let selectedComposition = this.composition[composition.name];
                    let selectedOptions = null;

                    if (selectedComposition) {
                        if (selectedComposition instanceof Array) {
                            selectedOptions = selectedComposition.map((item) => item.name);
                            let includesOption = composition.option.some((item) => selectedOptions.includes(item));

                            if (composition.condition == 'except' && includesOption) {
                                activePromotion = false;
                            }

                            if (composition.condition == 'including' && !includesOption) {
                                activePromotion = false;
                            }
                        } else {
                            let includesOption = composition.option.includes(selectedComposition.name);

                            if (composition.condition == 'except' && includesOption) {
                                activePromotion = false;
                            }

                            if (composition.condition == 'including' && !includesOption) {
                                activePromotion = false;
                            }
                        }
                    }
                })

                if (activePromotion) {
                    break;
                }
            }

            return activePromotion;
        },
    },
    watch: {
        quantity: function (newQuantity, oldQuantity) {
            let newQuantityf = parseFloat(newQuantity);
            let showMessageBool = this.product?.measure_unit == 'kg' ? newQuantityf > this.product?.stock : newQuantityf >= this.product?.stock;

            if (this.product?.stock_logic && showMessageBool) {
                this.$toast.info('Limite disponível em estoque atingido');
            }
        }
    },
    methods: {
        addToBag(composition, observations) {
            let newProduct = { ...this.product };
            newProduct.quantity = this.quantity;
            newProduct.compositions = composition;
            newProduct.totalPriceWithoutPromotion = this.totaPriceWithoutPromotion;
            newProduct.totalPrice = this.totalPrice;
            newProduct.observations = observations;
            newProduct.hasActivePromotion = this.hasActivePromotion;

            this.$store.commit('addItemToBag', {
                item: newProduct
            });

            this.closeProductModal();
        },
        subQuantity() {
            if (this.quantity == 1) return;

            this.quantity--;
        },
        addQuantity() {
            this.quantity++;
        },
        closeProductModal() {
            this.$emit('close-dialog');
        },
        ...mapActions(['findProduct'])
    }
}
</script>

<style scoped>
.card-dialog {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%;
}

.title-header {
    display: block;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-name {
    font-size: 0.95rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dialog-header {
    display: flex;
    align-items: center;
}

.dialog-image {
    height: 400px;
    width: 100% !important;
    position: relative;
}

.tags {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#add-to-cart {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 19px 20px;
    text-transform: capitalize;
}

.to-cart-container {
    position: fixed;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    bottom: 0;
    background-color: #fff;
}

.composition {

}

@media (min-width: 768px)
{
    .to-cart-container {
        height: 50px;
        position: relative;
    }
    .composition {
        max-height: 400px;
        overflow-y: auto;
    }
    .card-dialog {
        padding: 40px 20px;
        flex-direction: row;
    }
    .dialog-header {
        display: none;
    }
    .dialog-image {
        width: 60%;
    }
    .dialog-description {
        display: flex;
        flex-direction: column-reverse;
    }
}
</style>
