import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Search from '../views/Search.vue'
import Login from '../views/auth/Login'
import CreditCardIndex from '../views/cards/Index'
import CreditCardCreate from '../views/cards/Create'
import OrdersIndex from '../views/orders/Index'
import OrdersShow from '../views/orders/Show'
import AddressesIndex from '../views/addresses/Index'
import AddressesCreate from '../views/addresses/Create'
import AddressesEdit from '../views/addresses/Edit'
import Indications from '../views/Indications'
import UpdateProfile from '../views/UpdateProfile'
import ProductShow from '../views/products/Product'
import config from "@/config/api";
import Bag from '../views/Bag'
import Register from '../views/auth/Register'
import Checkout from '../views/Checkout'
import PageNotFound from '../views/404'

import auth from '../middleware/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search',
    name: 'home',
    component: Search
  },
  {
    path: '/produto/:id/',
    name: 'product-show',
    component: ProductShow,
  },
  {
    path: '/sacola',
    name: 'bag',
    component: Bag,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/cadastrar',
    name: 'register',
    component: Register
  },
  {
    path: '/cartoes',
    name: 'cards-index',
    component: CreditCardIndex,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/novo-cartao',
    name: 'cards-create',
    component: CreditCardCreate,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/pedidos',
    name: 'orders-index',
    component: OrdersIndex,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/pedidos/:id',
    name: 'orders-show',
    component: OrdersShow,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/enderecos',
    name: 'addresses-index',
    component: AddressesIndex,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/novo-endereco',
    name: 'addresses-create',
    component: AddressesCreate,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/indicacoes',
    name: 'recommendations',
    component: Indications,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/endereco/:id/editar',
    name: 'addresses-edit',
    component: AddressesEdit,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/editar-perfil',
    name: 'profile-edit',
    component: UpdateProfile,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next; 
  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (to.path === '/admin') {
    window.location.href = config.adminUrl;
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({...to, query: from.query});
  } else {
    next()
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router
