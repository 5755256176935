<template>
  <v-card
    :class="['credit-card', { 'current-card': current && current.id == card.id }]"
    :elevation="current && current.id == card.id ? 5 : 2"
    rounded="lg"
  >   
    <v-card-text>
      <v-row align="center" justify="space-between" class="mb-2">
        <v-col cols="auto">
          <v-avatar size="50" rounded>
            <v-img :src="card.thumbnail" />
          </v-avatar>
        </v-col>
        <v-col cols="auto">
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                @click="item.action"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center" @click="toggleCard">
        <v-col>
          <v-row no-gutters>
            <v-col cols="12">
              <span class="text-h6 font-weight-medium">**** {{ card.last_4 }}</span>
            </v-col>
            <v-col cols="12">
              <span class="text-caption text--secondary">Vencimento: {{ card.expiration_date }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-radio
            ref="radioBtn"
            :value="card.id"
            class="mt-0"
            hide-details
          ></v-radio>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    name: 'credit-card',
    props: ['card', 'current', 'theme'],
    data() {
        return {
            items: [
                { title: "Excluir", action: this.destroy}
            ]
        }
    },
    methods: {
        toggleCard() {
            let elem = this.$refs.radioBtn.$el;
            elem.click();
        },
        edit() {
            this.$router.push('/card/' + this.card.id + '/editar');
        },
        destroy() {
            this.$emit('destroy', this.card.id);
        },
        setCard(card) {
            this.$emit('settedCard', card);
        },
    }
}
</script>

<style scoped>
.credit-card {
  transition: all 0.3s ease;
  overflow: hidden;
}

.current-card {
  background-color: #FFA500 !important;
  color: #fff !important;
}

.current-card .v-list-item__title,
.current-card .text--secondary {
  color: #fff !important;
}

.v-btn--icon {
  transition: opacity 0.2s ease;
}

.credit-card:not(:hover) .v-btn--icon {
  opacity: 0.5;
}
</style>
