<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title>Selecione seu perfil</v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedProfile"
          :items="profileOptions"
          label="Tipo de perfil"
          required
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="confirmSelection" :disabled="!selectedProfile" :loading="loading">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProfileSelectionModal',
  data() {
    return {
      dialog: true,
      selectedProfile: null,
      loading: false,
      profileOptions: [
        { text: 'Cliente', value: 'customer' },
        { text: 'Garçom', value: 'bartender' }
      ]
    }
  },
  methods: {
    ...mapActions(['setCurrentProfile', 'getAuthenticatedUser']),
    async confirmSelection() {
      this.loading = true;

      this.$emit('profile-selected', this.selectedProfile);

      await this.handleProfileSelection(this.selectedProfile);

      this.dialog = false;
      this.loading = false;
    },

    async handleProfileSelection(profile) {
      await this.setCurrentProfile({ profile });

      let profileTranslations = {
        customer: 'Cliente',
        bartender: 'Garçom'
      }

      if (this.user) {
        await this.getAuthenticatedUser();
      }

      this.$toast.success(`Você está logado como ${profileTranslations[profile]}`);
      window.location.reload();
    },
  }
}
</script>
