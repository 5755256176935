<template>
  <div>
    <v-text-field
      v-model="formattedDatetime"
      :label="label"
      :placeholder="onlyDate ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'"
      prepend-icon="mdi-calendar"
      readonly
      @click="menu = true"
    >
      <template v-slot:append>
        <v-icon @click="clearDateTime">mdi-close</v-icon>
      </template>
    </v-text-field>

    <v-dialog
      v-model="menu"
      :width="340"
    >
      <v-card>
        <v-tabs v-model="activeTab" v-if="!onlyDate">
          <v-tab>Data</v-tab>
          <v-tab>Hora</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-date-picker
              v-model="date"
              :min="min"
              :first-day-of-week="1"
              :locale="ptBR"
              scrollable
              full-width
              @input="onDateSelected"
            ></v-date-picker>
          </v-tab-item>

          <v-tab-item v-if="!onlyDate">
            <v-time-picker
              v-model="time"
              format="24hr"
              scrollable
              full-width
              @input="onTimeSelected"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeMenu">Cancelar</v-btn>
          <v-btn text color="primary" @click="confirmSelection">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format, parse, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default {
  name: 'VDatetimePicker',
  props: {
    value: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Selecione data e hora',
    },
    placeholder: {
      type: String,
      default: 'DD/MM/YYYY HH:mm',
    },
    onlyDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      activeTab: 0,
      date: null,
      time: null,
      ptBR: ptBR,
    };
  },
  computed: {
    formattedDatetime: {
      get() {
        if (this.date) {
          if (this.onlyDate) {
            return format(parseISO(this.date), "dd/MM/yyyy", { locale: this.ptBR });
          } else if (this.time) {
            const datetime = parse(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm', new Date());
            return format(datetime, "dd/MM/yyyy HH:mm", { locale: this.ptBR });
          }
        }
        return '';
      },
      set(value) {
        if (value) {
          if (this.onlyDate) {
            const date = parse(value, 'dd/MM/yyyy', new Date());
            this.date = format(date, 'yyyy-MM-dd');
          } else {
            const datetime = parse(value, 'dd/MM/yyyy HH:mm', new Date());
            this.date = format(datetime, 'yyyy-MM-dd');
            this.time = format(datetime, 'HH:mm');
          }
          this.updateValue();
        } else {
          this.date = null;
          this.time = null;
          this.$emit('input', null);
        }
      },
    },
  },
  methods: {
    updateValue() {
      if (this.date) {
        if (this.onlyDate) {
          this.$emit('input', this.date);
        } else if (this.time) {
          const datetime = parse(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm', new Date());
          this.$emit('input', format(datetime, "yyyy-MM-dd'T'HH:mm:ss"));
        }
      }
    },
    clearDateTime() {
      this.date = null;
      this.time = null;
      this.$emit('input', null);
    },
    confirmSelection() {
      this.updateValue();
      this.closeMenu();
    },
    closeMenu() {
      this.menu = false;
      this.activeTab = 0;
    },
    onDateSelected(newDate) {
      this.date = newDate;
      if (this.onlyDate) {
        this.updateValue();
        this.closeMenu();
      }
    },
    onTimeSelected(newTime) {
      this.time = newTime;
      this.updateValue();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          if (this.onlyDate) {
            this.date = newValue;
          } else {
            const datetime = parseISO(newValue);
            this.date = format(datetime, 'yyyy-MM-dd');
            this.time = format(datetime, 'HH:mm');
          }
        } else {
          this.date = null;
          this.time = null;
        }
      },
    },
  },
};
</script>