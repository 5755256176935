var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade-shrink","appear":""}},[(!_vm.removed)?_c('v-card',{staticStyle:{"border-radius":"0px","background-color":"white","min-height":"100px"},attrs:{"width":"100%","elevation":"2"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex",staticStyle:{"overflow":"hidden","position":"relative"}},[_c('div',{staticClass:"d-flex flex-column justify-center",staticStyle:{"height":"100px","position":"relative"}},[_c('v-img',{staticClass:"mr-3",staticStyle:{"height":"100px","min-width":"100px !important","object-fit":"contain !important"},attrs:{"lazy-src":require("@/assets/product-image-placeholder.png"),"max-width":"130","src":_vm.product.imagem,"contain":""}}),(_vm.product.hasActivePromotion)?_c('v-chip',{staticClass:"promotion green lighten-1",attrs:{"color":"green","x-small":""}},[_vm._v(" PROMOÇÃO ")]):_vm._e()],1),(_vm.product.free_rate_quantity)?_c('div',{staticClass:"ribbon ribbon-top-left ribbon-blue"},[_c('span',[_vm._v("Frete grátis")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-column pt-2",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticStyle:{"line-height":"1rem","height":"50px"}},[_c('span',{staticClass:"font-weight-medium product-name"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"my-1 tags",staticStyle:{"height":"45px","overflow-y":"auto","font-size":"11px"}},[_c('span',[_vm._v(_vm._s(_vm.product.tags ? _vm.product.tags.join(", ") : "tag"))])])]),_c('div',{staticClass:"flex-grow-1 d-flex flex-column justify-end pr-md-2"},[_c('div',{staticClass:"d-flex flex-column flex-md-row justify-space-between overflow-hidden"},[(
                  (_vm.product.promotions[0] ?? null) &&
                  _vm.product.promotions[0].compositions.length == 0
                )?_c('div',[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("De: "),_c('s',[_vm._v(_vm._s(_vm.formatMoney(_vm.getProductPrice(_vm.product) * _vm.product.quantity)))])]),(_vm.product.promotions[0].discount_type !== 'fixed')?_c('v-chip',{staticClass:"ml-1",attrs:{"color":"success","x-small":""}},[_vm._v(" -"+_vm._s(_vm.product.promotions[0].discount_value / 100)+"% ")]):_vm._e(),_c('br'),_c('span',{staticClass:"title primary--text"},[_vm._v("Por: "+_vm._s(_vm.formatMoney(_vm.product.totalPrice * _vm.product.quantity)))])],1):_c('div',[_c('span',{staticClass:"title primary--text",staticStyle:{"font-size":"18px !important"}},[_vm._v(_vm._s(_vm.formatMoney(_vm.product.totalPrice * _vm.product.quantity)))])]),_c('v-card-actions',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center px-0",staticStyle:{"border":"1px solid #ccc","border-radius":"25px"}},[_c('v-btn',{staticClass:"transparent",staticStyle:{"padding":"0px !important","border-radius":"25px"},attrs:{"x-small":"","outlined":"","plain":"","color":_vm.product.quantity > 1 ? 'primary' : 'dark'},on:{"click":_vm.subQuantity}},[(_vm.product.quantity > 1)?_c('span',[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-minus")])],1):_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-delete")])],1),_c('span',{staticClass:"gray--text text-center",staticStyle:{"font-size":"15px","min-width":"40px"}},[_vm._v(" "+_vm._s(_vm.product.quantity)+" ")]),_c('v-btn',{staticClass:"transparent",staticStyle:{"padding":"0px !important","border-radius":"25px"},attrs:{"x-small":"","disabled":_vm.product?.stock_logic &&
                        _vm.product.quantity >= _vm.product?.stock
                          ? true
                          : false,"color":"primary","outlined":"","plain":""},on:{"click":_vm.addQuantity}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-plus")])],1)],1)])])],1)]),_c('v-icon',{staticClass:"trash",attrs:{"color":"danger","x-small":""},on:{"click":function($event){return _vm.$emit('deleted', _vm.product.id)}}},[_vm._v("mdi-delete")])],1)]),_c('bag-product-detail',{attrs:{"product":_vm.product}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }