<template>
    <v-bottom-navigation
      class="navigation" fixed grow
    >
      <v-btn height="100%" text @click="goTo('/')">
        <span style="font-size: 0.7rem; color: white;">Início</span>
        <v-icon color="white">mdi-home-outline</v-icon>
      </v-btn>
      <v-btn height="100%" text>
        <span style="font-size: 0.7rem; color: white;" @click="goTo('/search')">Buscar</span>
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn>
      <v-btn height="100%" text @click="goTo('/sacola')">
        <span style="font-size: 0.7rem; color: white;">Sacola</span>
        <v-icon color="white" v-if="bag.length == 0">mdi-purse-outline</v-icon>
        <v-badge
          v-else
          color="green"
          overlap
          text
          :value="showBag"
          :content="bag.reduce(
            (acc, product) => acc + (product.measure_unit == 'kg' ? 1 : product.quantity ?? 0),
            0
          )"
        >
          <v-icon color="white">mdi-purse-outline</v-icon>
        </v-badge>
      </v-btn>
      <v-btn active="transparent" height="100%" light @click="openDrawer">
        <span style="font-size: 0.7rem; color: white;">Conta</span>
        <v-icon color="white">mdi-account-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "TextField",
    props: ['drawer'],
    computed: {
    ...mapState({
        bag: state => state.bag.bag
      })
    },
    methods: {
        focusSearch() { this.$emit('focus-search'); },
        goTo(to){
            if (this.$route.path === to) return;

            if (to === 'sair') {
                this.logout();
                return;
            }
            
            this.$router.push(to);
        },
        showBag() {
            return this.bag.length > 0;
        },
        openDrawer() {
            this.$emit('open-drawer');
        }
    }
}
</script>

<style scoped>
    .navigation {
        background-color: var(--v-primary);
        z-index: 900 !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    @media (min-width: 768px) {
        .navigation {
            display: none;
        }
    }

    .navigation .v-btn span,
    .navigation .v-btn .v-icon {
        color: white !important;
    }
</style>