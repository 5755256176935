import client from '@/api/client';
import config from '@/config/api';

import { authenticated } from '@/utils/auth';

export async function getAuthenticatedUser() {
    return await client.get("/auth-customer");
}

export async function login({ email, phone, password }) {
    let data = {
        "email": email,
        "phone": phone,
        "password": password,
        "device_name": "sadsad"
    }

    return await client.post("/login", data);
}

export async function setCurrentProfile({ profile }) {
    return await client.post("/customers/set-current-profile", { profile });
}
