<!-- <template>
  <div>
    <v-carousel
      v-if="images.length > 1"
      :hide-delimiter-background="true"
      :show-arrows="images.length > 1"
      height="400"
    >
      <template #default="{ item, index }">
        <v-carousel-item
          v-for="(imageSrc, i) in images"
          :key="i"
          :src="imageSrc"
          reverse-transition="fade-transition"
          transition="fade-transition"
          cover
        ></v-carousel-item>
      </template>
      
      <template #delimiter="{ index }">
        <v-img
          :src="images[index]"
          contain
          max-width="40"
          max-height="40"
          class="ma-2"
        ></v-img>
      </template>
    </v-carousel>

    <img
      v-else
      lazy-src="@/assets/product-image-placeholder.png"
      style="width: 100%; max-width: 600px; height: 400px; object-fit: cover;"
      @error="$event.target.src=require(`@/assets/product-image-placeholder.png`)"
      :src="images[0] ?? null"
      cover
    >
  </div>
</template> -->
<template>
  <div class="gallery-container mx-0 mx-5">
    <!-- Imagem Principal/Destaque -->
    <div class="main-image">
      <img
        :src="currentImage"
        :lazy-src="'@/assets/product-image-placeholder.png'"
        @error="$event.target.src=require(`@/assets/product-image-placeholder.png`)"
        style="width: 100%; max-width: 600px; height: 340px; object-fit: cover;"
      >
    </div>

    <!-- Thumbnails -->
    <div v-if="images.length > 1" class="thumbnails" style="margin-top: -10px;">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="thumbnail"
        :class="{ 'active': currentIndex === index }"
        @click="setCurrentImage(index)"
      >
        <img
          :src="image"
          :lazy-src="'@/assets/product-image-placeholder.png'"
          @error="$event.target.src=require(`@/assets/product-image-placeholder.png`)"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['images'],
  data() {
    return {
      currentIndex: 0
    }
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex] ?? null
    }
  },
  methods: {
    setCurrentImage(index) {
      this.currentIndex = index
    }
  }
}
</script>

<style scoped>
.gallery-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.thumbnails {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}

.thumbnail {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s;
}

.thumbnail.active {
  border-color: #1976D2; /* Cor do Vuetify primary */
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
