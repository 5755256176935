<template>
  <v-card class="current-address-card" flat>
    <v-card-text>
      <v-row align="center" no-gutters>
        <v-col cols="12" sm="9">
          <h3 class="text-subtitle-1 font-weight-medium mb-2">
            Endereço de entrega
          </h3>
          <p class="mb-1">
            {{ address?.street }}, {{ address?.number }}
            <!-- TODO: Adicionar referência -->
          </p>
          <p class="text-caption mb-1">
            <span v-if="address?.district">{{ address?.district }}</span>
            <span v-if="address?.city"> - {{ address?.city }}</span>
            <span>{{ address?.state }}</span>
          </p>
          <p class="text-caption mt-2">
            <v-icon small class="mr-1">mdi-tag</v-icon>
            Identificador: {{ address?.name }}
          </p>
          <p v-if="theme?.district_tax && address?.tax > 0" class="text-caption">
            <v-icon small class="mr-1">mdi-truck-delivery</v-icon>
            Taxa de entrega: {{ formatMoney(address?.tax) }}
          </p>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex justify-end align-center">
          <v-btn
            v-if="otherAddress"
            text
            small
            color="primary"
            @click="$emit('showSheet', true)"
          >
            Alterar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CurrentAddressCard",
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
    otherAddress: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatMoney(value) {
      return `R$ ${value.toFixed(2)}`;
    },
  },
};
</script>

<style scoped>
.current-address-card {
  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
}

.v-card__text {
  padding: 16px 0;
}

h3 {
  color: #424242;
}

p {
  color: #616161;
  margin-bottom: 4px;
}

.text-caption {
  color: #757575;
}

.v-icon {
  font-size: 14px;
}
</style>
