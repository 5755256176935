<template>
  <v-card class="mb-4 pa-4">
    <div v-if="isFreightFree" class="d-flex align-center success--text">
      <v-icon color="success" class="mr-2">mdi-truck-check</v-icon>
      <span>Parabéns! Você ganhou Frete Grátis</span>
    </div>
    <div v-else>
      <v-progress-linear
        :value="progress"
        height="10"
        rounded
        color="success"
      >
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <div class="mt-2 text-center d-flex align-center justify-center">
        <v-icon color="success" class="mr-2">mdi-truck</v-icon>
        Compre mais R$ {{ formatMoney(remaining / 100) }} e ganhe Frete Grátis
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'FreeShippingProgress',
  props: {
    total: {
      type: Number,
      required: true
    },
    threshold: {
      type: Number,
      required: true
    }
  },
  computed: {
    showProgress() {
      return this.threshold && this.threshold > this.total;
    },
    progress() {
      if (!this.threshold) return 0;
      return (this.total / this.threshold) * 100;
    },
    remaining() {
      if (!this.threshold) return 0;
      return Math.max(0, this.threshold - this.total);
    },
    isFreightFree() {
      return this.total >= this.threshold;
    }
  },
  methods: {
    formatMoney(value) {
      // Implemente a formatação de dinheiro aqui
      return value.toFixed(2).replace('.', ',');
    }
  }
}
</script>
