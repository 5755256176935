<template>
    <v-card outlined class="card-product-list" @click="openProductModal()">
        <div class="d-flex" style="overflow: hidden; position: relative;">
            <v-img 
                style="border-radius: 10px; height: 150px; min-width: 150px !important; object-fit: contain !important;"
                class="mr-3" lazy-src="@/assets/product-image-placeholder.png" max-width="130"
                :src="product.imagem"
                contain
            />
            <div v-if="!isAvailableOnStock(product)" class="ribbon ribbon-top-left ribbon-secondary">
                <span>Indisponível</span>
            </div>
            <div v-else-if="product.free_rate_quantity" class="ribbon ribbon-top-left ribbon-blue">
                <span>Frete grátis</span>
            </div>
            <v-chip v-if="product.has_promotions" class="promotion green lighten-1" color="green" x-small>
                PROMOÇÃO
            </v-chip>
            <div class="d-flex flex-column justify-space-between overflow-hidden pt-1">
                <div style="line-height: 1rem; height: 50px;">
					<span class="font-weight-medium product-name">{{ product.name }}</span>
					<div class="my-1 tags" style="height: 45px; overflow-y: auto; font-size: 11px;">
						<span>{{ product.tags ? product.tags.join(', ') : 'tag' }}</span>
					</div>
				</div>

                <div class="d-flex flex-column justify-end">
					<div class="my-3" style="line-height: 1rem;">
						<div v-if="(product.promotions[0] ?? null) && product.promotions[0].compositions.length == 0">
							<div class="d-flex align-center mb-2">
								<span class="gray--text text--lighten-3" style="font-size: 14px;">
									De: <s>{{ formatMoney(getProductPrice(product)) }}</s>
								</span>
                                <v-chip
									v-if="product.promotions[0].discount_type !== 'fixed'"
									class="ml-1 font-weight-medium"
									color="success" x-small
								>
									-{{ product.promotions[0].discount_value / 100 }}%
								</v-chip><br/>
							</div>
							<span class="primary--text font-weight-medium" style="font-size: 1.3rem;">
								Por: {{ formatMoney(getProductPrice(product) - getPromotionDiscount(product.promotions[0], product.price)) }}
							</span>
						</div>
						<div v-else>
							<span class="primary--text font-weight-medium" style="font-size: 1.3rem;">
                                {{ formatMoney(product.price || product?.compositions[0]?.options[0]?.additional_price || 0) }}
                            </span>
						</div>
					</div>
				</div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "ProductListCard",
    props: ['product'],
    methods: {
        goToProduct(productId) {
            this.$router.push(`produto/${productId}`);
        },
        openProductModal() {
            if (!this.isAvailableOnStock(this.product)) {
                this.$toast.info('Produto indisponível no estoque');
                return false;
            }

            this.$emit('open-dialog', this.product);
        }
    }
}
</script>

<style>
.card-product-list {
    border-radius: 15px !important; 
    height: 150px !important;
    overflow: hidden;
    border: 1px solid #eee !important;
}

.card-product-list:hover {
    border-color: #bbb !important;
}

.product-name {
	font-size: 0.90rem;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tags {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    font-size: 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

.ribbon-red span {
    background-color: #DD1C1E;
}

.ribbon-blue span {
	background-color: #2980b9;
}

.ribbon-secondary span {
    background-color: #A6A6A6;
}

/* top left*/
.ribbon-top-left {
    top: -15px;
    left: -20px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    height: 4px;
    padding-bottom: 18px;
    padding-top: 7px;
    padding-right: 20px;
    transform: rotate(-45deg);
}

.promotion {
	color: #fff !important;
	position: absolute;
    border-radius: 0px 20px 0px 0px !important;
    left: 0;
	bottom: 3px !important;
}

@media (max-width: 768px) {
    .card-product-list {
        border-radius: 10px !important;
    }
}
</style>