<template>
  <div class="text-center">
    <v-dialog
      :value="value"
      @input="update"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="font-size: 12px;"
          class="green text-center text-uppercase"
          width="100%"
          depressed
          block
          v-bind="attrs"
          v-on="on"
        >
          <span class="text-capitalize gray--text text--lighten-5">Detalhes promoção</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 white--text success">
          <span v-if="promotions.length > 1">Promoções</span>
          <span v-else>Promoção</span>
        </v-card-title>

        <v-card-text>
          <span v-for="(promotion, i) in promotions" :key="i">
            <span class="title" v-if="promotions.length > 1">Promoção {{ i + 1 }}</span>
            <v-divider class="pb-4"></v-divider>
            <span>Na compra desse produto</span>
            <span v-if="promotion?.compositions?.length">
              com composição: <br/><span class="font-weight-bold" v-html="promotionText(promotion)"></span><br />
            </span>
            <span v-if="promotion?.discount_type == 'final_price'">
              O valor final do produto será <span class="font-weight-bold">{{ promotionDiscountText(promotion) }}.</span>
            </span>
            <span v-else>
              você receberá um desconto de <span class="font-weight-bold">{{ promotionDiscountText(promotion) }}.</span><br />
            </span>
          </span>

          <br />
          <span 
            v-if="theme?.not_allowed_payment_methods_for_promotions?.length > 0" 
            class="font-weight-bold">
              * Aplicavel para todos métodos de pagamento exceto: <br/> 
              {{ theme?.not_allowed_payment_methods_for_promotions.map((paymentMethod) => translatePaymentMethod(paymentMethod)).join(', ') }}
          </span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="update(false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'promotions-dialog',
    props: ['closed', 'schedule', 'value', 'promotions', 'theme'],
    data () {
      return {
        dialog: false,
      }
    },
    methods: {
      update(newValue) { this.$emit('input', newValue); }
    }
  }
</script>