<template>
  <div class="error-page">
    <div class="error-content">
      <h1 class="error-title">404</h1>
      <div class="glitch-wrapper">
        <div class="glitch" data-text="Ops...">Ops...</div>
      </div>
      <p class="error-message">Página não encontrada</p>
      <v-btn class="mt-5" color="primary" @click="$router.go(-1)" elevation="2">
        <v-icon left>mdi-arrow-left</v-icon>
        Voltar
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
.error-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.error-content {
  text-align: center;
}

.error-title {
  font-size: 120px;
  font-weight: bold;
  color: #1976D2;
  margin-bottom: 0;
}

.glitch-wrapper {
  margin-bottom: 20px;
}

.glitch {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #2c3e50;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #ff00c1;
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
  animation: glitch-anim2 1s infinite linear alternate-reverse;
}

.error-message {
  font-size: 24px;
  color: #34495e;
  margin-bottom: 30px;
}

@keyframes glitch-anim {
  0% {
    clip: rect(10px, 9999px, 31px, 0);
    transform: skew(0.55deg);
  }
  5% {
    clip: rect(70px, 9999px, 71px, 0);
    transform: skew(0.03deg);
  }
  /* ... adicione mais etapas de animação aqui ... */
  100% {
    clip: rect(67px, 9999px, 86px, 0);
    transform: skew(0.84deg);
  }
}

@keyframes glitch-anim2 {
  0% {
    clip: rect(65px, 9999px, 99px, 0);
    transform: skew(0.6deg);
  }
  5% {
    clip: rect(71px, 9999px, 64px, 0);
    transform: skew(0.44deg);
  }
  /* ... adicione mais etapas de animação aqui ... */
  100% {
    clip: rect(40px, 9999px, 73px, 0);
    transform: skew(0.05deg);
  }
}
</style>
