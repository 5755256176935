<template>
  <div>
    <div v-if="theme?.product_display == 'grid'" class="category-title text-uppercase mb-2 p-list" style="text-transform: capitalize !important;">
      {{ category }}
    </div>
    <v-sheet class="mx-auto" max-width="100%">
      <div class="px-4">
        <div class="d-flex align-center">
          <!-- <v-btn-toggle active-class="primary white--text" rounded multiple class="pa-0 pr-4 btn-filter">
            <v-btn depressed max-height="30" class="text-capitalize" style="font-size: 0.8rem;" rounded
              @click="freeShipping = !freeShipping">Frete Gratis</v-btn>
            <v-btn depressed max-height="30" class="text-capitalize" style="font-size: 0.8rem;" rounded
              @click="available = !available">Disponíveis</v-btn>
          </v-btn-toggle> -->
        </div>
      </div>
      
      <!-- Exibição em lista com expansion panels -->
      <v-expansion-panels v-if="theme?.product_display == 'list'" :value="[0]" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ category }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-wrap px-1">
              <template v-for="(product, i) in products">
                <v-col cols="12" lg="4" md="6" :key="i">
                  <product-list-card :product="product" @open-dialog="$emit('open-dialog', product)" />
                </v-col>
              </template>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Exibição em grid -->
      <div v-if="theme?.product_display == 'grid'" class="py-5">
        <div class="mx-5 mx-md-10 my-3 d-flex justify-space-between justify-md-end">
          <div v-if="theme?.product_display == 'grid'" class="category-title text-uppercase p-list-mobile" style="text-transform: capitalize !important;">
            {{ category }}
          </div>
          <div style="min-width: 200px;" v-if="!isFiltered">
            <div class="d-flex justify-end align-center">
              <!-- Adicionando os botões de navegação -->
              <div class="navigation-buttons mr-4 d-none d-md-flex">
                <v-btn
                  rounded
                  small
                  color="primary"
                  class="mr-2"
                  @click="$refs.productSwiper.slidePrev()"
                  :disabled="isBeginning"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  rounded
                  small
                  color="primary"
                  @click="$refs.productSwiper.slideNext()"
                  :disabled="isEnd"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
              <div class="mr-0 mr-md-4" v-if="products.length > 4">
                <span class="font-weight-bold" style="font-size: 1.1rem; cursor: pointer; color: var(--v-primary);" @click="$emit('categoryFilter', category)">ver mais</span>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Grid normal quando filtrado -->
        <v-row v-if="isFiltered" class="mx-2">
          <v-col 
            v-for="(product, index) in products" 
            :key="index"
            cols="6"
            sm="6"
            md="4"
            lg="3"
          >
            <product-grid-card 
              :product="product" 
              @open-dialog="$emit('open-dialog', product)"
            />
          </v-col>
        </v-row>

        <!-- Swiper quando não filtrado -->
        <product-swiper 
          v-else
          ref="productSwiper"
          :products="products"
          @open-dialog="$emit('open-dialog', $event)"
          @state-change="onSwiperStateChange"
        />
      </div>
    </v-sheet>
  </div>
</template>

<script>
import ProductGridCard from "./cards/ProductGridCard";
import ProductListCard from "./cards/ProductListCard";
import ProductDialog from "./ProductDialog";
import ProductSwiper from './slides/ProductSwiper'

export default {
  name: "ProductList",
  props: {
    products: Array,
    theme: Object,
    category: String,
    isFiltered: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProductGridCard,
    ProductListCard,
    ProductDialog,
    ProductSwiper
  },
  data() {
    return {
      dialog: false,
      freeShipping: false,
      available: false,
      selectedProduct: null,
      isBeginning: true,
      isEnd: false,
    };
  },
  methods: {
    onSwiperStateChange(swiper) {
      this.isBeginning = swiper.isBeginning;
      this.isEnd = swiper.isEnd;
    }
  }
};
</script>

<style lang="scss" scoped>
.product-swiper {
    position: relative;
    width: 100%;
    padding: 0 40px;
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--v-primary-base);
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 24px;
}

@media (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}

.category-title {
    font-weight: 700;
    font-size: 20px;
}

.card-category {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-product-mobile {
    display: block;
}

@media (max-width: 768px) {
    .btn-filter {
        padding: 0 !important;
    }

    .btn-controll {
        display: none;
    }

    .card-category {
        flex-direction: column;
        align-items: flex-start;
    }
}

.navigation-buttons {
  .v-btn.v-btn--disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
}

.v-row {
  margin: 0 -8px;
}

.v-col {
  padding: 8px;
}

@media (min-width: 768px) {
  .v-row {
    margin: 0 -12px;
  }

  .v-col {
    padding: 12px;
  }
}

/* Ajustes para o layout mobile */
@media (max-width: 767px) {
  .category-title {
    font-size: 16px;
  }

  .mx-5 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .py-5 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}
</style>
