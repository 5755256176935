<template>
  <div class="d-md-flex justify-md-center">
    <v-layout style="max-width: 1400px" column class="mt-10">
      <page-title title="checkout"></page-title>
      <v-container class="mt-10 px-6 px-md-0">
        <v-row class="d-flex flex-column flex-md-row">
          <v-col sm="12" md="8" class="pa-0 pa-md-3">
            <v-card class="mb-0 mb-md-6">
              <v-card-title>Informações do Pedido</v-card-title>
              <v-card-text>
                <div v-if="user && !newAddress">
                  <div class="d-flex justify-center my-2">
                    <withdraw-option v-if="theme.show_withdraw_option" :theme="theme" :table="table" :user="user" v-model="withdraw" />
                  </div>
                  <current-address-card
                    v-if="withdraw == 'delivery' && theme?.form?.show_address_fields !== false"
                    :otherAddress="true"
                    :theme="theme"
                    @showSheet="openSheet"
                    :address="user.current_address[0]"
                  ></current-address-card>
                </div>
                <customer-form
                  v-if="!user"
                  v-model="customer"
                  :theme="theme"
                  :loading="loading || authLoading"
                  :errors="orderErros"
                  action="create"
                ></customer-form>
                <v-card v-if="user?.profile === 'bartender'" class="mt-6">
                  <v-card-title>Informações da Mesa</v-card-title>
                  <v-card-text>
                    <v-select
                      v-model="selectedTable"
                      :items="tables"
                      item-text="name"
                      item-value="id"
                      label="Selecione a mesa"
                      rounded
                      dense
                      outlined
                      required
                      :error-messages="orderErros?.table_id"
                    ></v-select>
                    <v-text-field
                      v-model="customerName"
                      label="Nome do cliente (opcional)"
                      rounded
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="customerPhone"
                      label="Telefone do cliente (opcional)"
                      rounded
                      dense
                      outlined
                      v-mask="'(##) #####-####'"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>

            <v-card v-show="(!user && showNextStep) || newAddress" class="mb-6">
              <v-card-title>Endereço de Entrega</v-card-title>
              <v-card-text>
                <div class="d-flex justify-center my-2">
                  <withdraw-option v-if="theme.show_withdraw_option" :theme="theme" :table="table" v-model="withdraw" />
                </div>
                <address-form
                  v-show="withdraw == 'delivery' && theme?.form?.show_address_fields !== false"
                  v-model="address"
                  @updateTax="updateTax"
                  :theme="theme"
                  :loading="loading"
                  :errors="orderErros"
                ></address-form>
              </v-card-text>
            </v-card>

            <v-card v-show="showNextStep && theme?.form?.show_payment_method !== false && user?.profile != 'bartender'" class="mb-6">
              <v-card-title>Forma de Pagamento</v-card-title>
              <v-card-text>
                <v-select
                  v-if="!table"
                  :items="paymentModes"
                  v-model="paymentMode"
                  :error="!!orderErros?.payment_method"
                  :disabled="!theme?.online_payment"
                  @change="changedPaymentMethod"
                  :error-messages="orderErros?.payment_method && orderErros?.payment_method[0]"
                  placeholder="Modo de pagamento"
                  rounded
                  dense
                  outlined
                ></v-select>
                <v-select
                  v-show="paymentMode"
                  :items="paymentMethods"
                  v-model="selectedPaymentMethod"
                  :error="!!orderErros?.payment_method"
                  :disabled="disablePaymentMethod"
                  @change="changedPaymentMethod"
                  :error-messages="orderErros?.payment_method && orderErros?.payment_method[0]"
                  placeholder="Selecione o método de pagamento"
                  rounded
                  dense
                  outlined
                ></v-select>
                <v-textarea
                  v-if="selectedPaymentMethod == 'money'"
                  outlined
                  rows="2"
                  name="input-7-4"
                  color="primary"
                  v-model="change_value"
                  placeholder="Ex:. Troco para 100"
                  label="Troco"
                ></v-textarea>
                <copy-to-clipboard
                  v-if="selectedPaymentMethod == 'pix' && theme.pix_key"
                  :copy="theme.pix_key"
                  :pix_name="theme.pix_name"
                ></copy-to-clipboard>
              </v-card-text>
            </v-card>

            <v-card v-if="paymentMode == 'online' && selectedPaymentMethod == 'credit_card'" class="mb-6">
              <v-card-title>Cartão para pagamento</v-card-title>
              <v-card-text>
                <current-card
                  v-if="user.current_card[0] ?? null"
                  :theme="theme"
                  @showSheet="openCardSheet"
                  :otherCards="true"
                  :card="user.current_card[0] ?? null"
                ></current-card>
                <div v-else>
                  <div class="text-center">
                    <span class="gray--text" style="font-size: 16px">Você ainda não possui cartão cadastrado para pagamento</span>
                  </div>
                  <v-btn
                    class="mt-3"
                    color="primary"
                    block
                    @click="openCardSheet"
                  >Adicionar</v-btn>
                </div>
              </v-card-text>
            </v-card>

            <v-card v-show="showNextStep && theme?.active_order_observations" class="mb-6">
              <v-card-title>Observações</v-card-title>
              <v-card-text>
                <v-textarea
                  outlined
                  rows="2"
                  name="input-7-4"
                  color="primary"
                  v-model="observations"
                  placeholder="Alguma informação adicional sobre o pedido?"
                ></v-textarea>
              </v-card-text>
            </v-card>

            <v-card v-if="theme.allow_scheduling" class="mb-6">
              <v-card-title>Agendar pedido?</v-card-title>
              <v-card-text>
                <v-btn
                  @click="openScheduleDialog"
                  color="primary"
                  block
                >
                  {{ scheduledDateTime ? 'Cancelar agendamento' : 'Agendar' }}
                </v-btn>
                <span v-if="orderErros?.scheduled_datetime && (orderErros?.scheduled_datetime[0] ?? false) && !formattedScheduledDateTime" class="red--text">{{ orderErros?.scheduled_datetime[0] ?? '' }}</span>
                <div v-if="scheduledDateTime" class="mt-3">
                  <span>Pedido agendado para: {{ formattedScheduledDateTime }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col sm="12" md="4" class="pa-0 pa-md-3 mt-6 mt-md-0">
            <v-card class="pa-4 mb-16">
              <price-summary
                :total="summary?.total"
                :table="table"
                :tax="tax"
                :theme="theme"
                :cashbackToUse="summary?.cashbackToUse ?? 0"
                :themeLoading="themeLoading"
                :coupon="coupon"
              />
              <div style="margin-top: 20px">
                <v-btn
                  v-show="showNextStep"
                  color="primary"
                  @click="createOrder"
                  :disabled="paymentMode == 'online' && selectedPaymentMethod == 'credit_card' && !(user.current_card[0] ?? null)"
                  rounded
                  :loading="orderLoading || authLoading || checkoutLoading"
                  block
                >
                  FINALIZAR COMPRA
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <!-- Sheets e Dialogs -->
    <address-bottom-sheet
      v-if="theme?.form?.show_address_fields !== false"
      v-model="sheet"
      :theme="theme"
      @updateTax="updateTax"
      :addresses="addresses"
      :loading="addressLoading"
      @close="sheet = false"
      @newAddress="
        newAddress = true;
        sheet = false;
      "
    ></address-bottom-sheet>
    <card-bottom-sheet
      v-model="cardSheet"
      :theme="theme"
      :cards="cards"
      :loading="cardLoading"
      @close="cardSheet = false"
    ></card-bottom-sheet>
    <card-cvv-sheet
      v-model="cvvSheet"
      :theme="theme"
      :cvv="cardCvv"
      @cvv="cardCvv = $event"
      :loading="orderLoading"
      @close="cvvSheet = false"
      @submit="createOrder"
    ></card-cvv-sheet>
    <v-dialog v-model="scheduleDialog" max-width="350px">
      <v-card>
        <v-card-title>Agendar pedido</v-card-title>
        <v-card-text>
          <v-datetime-picker
            v-model="tempScheduledDateTime"
            :rules="scheduledDateTimeRules"
            :error-messages="orderErros?.scheduled_datetime"
            :label="onlyDateForScheduling ? 'Data do agendamento' : 'Data e hora do agendamento'"
            :min="new Date().toISOString().substr(0, 16)"
            :required="theme.only_scheduled_orders"
            :onlyDate="onlyDateForScheduling"
          ></v-datetime-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeScheduleDialog">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="confirmSchedule">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddressBottomSheet from "@/components/sheets/AddressBottomSheet";
import CardBottomSheet from "@/components/sheets/CardBottomSheet";
import CardCvvSheet from "@/components/sheets/CardCvvSheet";
import AddressForm from "@/components/forms/Address";
import CurrentCard from "@/components/cards/CurrentCard";
import CustomerForm from "@/components/forms/Customer";
import PriceSummary from "../components/PriceSummary";
import WithdrawOption from "@/components/inputs/WithdrawOption";
import CopyToClipboard from "@/components/inputs/CopyToClipboard";
import CurrentAddressCard from "@/components/cards/CurrentAddressCard";
import PageTitle from "@/components/layouts/PageTitle";
import VDatetimePicker from '@/components/inputs/VDatetimePicker.vue';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { mask } from 'vue-the-mask';

export default {
  name: "checkout",
  directives: {
    mask
  },
  data() {
    return {
      paymentModes: [
        { value: "on_delivery", text: "Na entrega" },
        { value: "online", text: "Online" },
      ],
      paymentMode: 'on_delivery',
      checkoutLoading: false,
      salesman_code: null,
      indicator_code: null,
      sheet: false,
      cardSheet: false,
      cvvSheet: false,
      cardCvv: null,
      newTax: null,
      observations: "",
      newAddress: false,
      withdraw: "delivery",
      showNextStep: true,
      withoutPromotion: false,
      customer: {
        name: "",
        email: "",
        phone: "",
        birth_date: "",
        password: "",
        password_confirmation: "",
      },
      address: {
        name: "",
        zipcode: "",
        state: "",
        city: "",
        street: "",
        district: "",
        complement: "",
        number: "",
      },
      selectedPaymentMethod: "",
      disablePaymentMethod: false,
      change_value: "",
      scheduledDateTime: null,
      scheduleDialog: false,
      tempScheduledDateTime: null,
      onlyDateForScheduling: false,
      selectedTable: null,
      customerName: "",
      customerPhone: "",
    };
  },
  watch: {
    phoneNumber: async function (newPhone, oldPhone) {
      if (!this?.theme?.form?.show_email_field && newPhone.length == 15) {
        await this.login({
          phone: this.customer.phone,
          withoutRedirect: true,
        });

        if (this.user) {
          this.$toast.success(`Bem vindo, ${this.user.name}`);
        }

        this.showNextStep = true;
      }
    },
  },
  computed: {
    paymentMethods() { 
        let paymentMethodsObject = [
          { value: "money", text: "Dinheiro" },
          { value: "credit_card", text: "Cartão de crédito" },
          { value: "debit_card", text: "Cartão de débito" },
          { value: "pix", text: "PIX" },
        ];

        if (this.paymentMode == "online") {
          paymentMethodsObject = [
            { value: "credit_card", text: "Cartão de crédito" },
            { value: "pix", text: "PIX" },
          ];
        }

        let paymentMethods = this.theme.payment_methods;
        let totalToPay = this.total + this.tax - this.getCouponDiscount(this.coupon, this.total + this.tax) - this.summary?.cashbackToUse;

        if (paymentMethods.length > 0) {
          paymentMethodsObject = paymentMethodsObject.filter((method) =>
            paymentMethods.includes(method.value)
          );
        }

        if (this?.salesman_code) {
          paymentMethodsObject.push({ value: "deadline", text: "Prazo" });
        }

        if (this.summary?.cashbackToUse && totalToPay === 0) {
          paymentMethodsObject.push({ value: "credits", text: "Créditos" });
          this.selectedPaymentMethod = "credits";
          this.disablePaymentMethod = true;
        }

        let NotAlloweds = this.theme?.not_allowed_payment_methods_for_promotions;

        return paymentMethodsObject.map((paymentMethod) => {
          if (NotAlloweds && NotAlloweds.includes(paymentMethod.value)) {
            paymentMethod.text = paymentMethod.text + " (Não elegível para promoções)";

            return paymentMethod
          }

          return paymentMethod
        });
    },
    phoneNumber() {
      return this.customer.phone;
    },
    total() {
      let self = this;

      return Math.ceil(
        this.bagProducts.reduce(
          (acc, { compositions, totalPrice, totalPriceWithoutPromotion, price, quantity }) => {
            if (self.withoutPromotion && totalPriceWithoutPromotion) {
              totalPrice = totalPriceWithoutPromotion;
            }

            return (
              acc +
              (totalPrice) * (quantity ?? 1)
            );
          },
          0
        )
      );
    },
    tax() {
      if (!this.theme?.tax && !this?.newTax) {
        return 0;
      }

      let tax =
        this?.newTax && this?.newTax > 0 ? this?.newTax : this?.theme?.tax;

      let hasFreeRateProductBonus = this.bagProducts.findIndex(
        (bagProduct) =>
          bagProduct?.free_rate_quantity &&
          (bagProduct?.quantity ?? 0) >= (bagProduct?.free_rate_quantity ?? 0)
      );

      if (hasFreeRateProductBonus >= 0) {
        return 0;
      }

      if (this.withdraw == 'withdraw') {
        return 0;
      }

      let total = this.summary?.total ?? this.total;

      return this.theme?.free_rate_from &&
        this.theme?.free_rate_from <=
          total - this.getCouponDiscount(this.coupon, this.total + tax)
        ? 0
        : tax;
    },
    ...mapState({
      theme: (state) => state.theme.theme,
      table: (state) => state.tables.table,
      themeLoading: (state) => state.theme.loading,
      user: (state) => state.auth.user,
      bagProducts: (state) => state.bag.bag,
      summary: (state) => state.bag.summary,
      addresses: (state) => state.addresses.addresses,
      cards: (state) => state.cards.cards,
      addressLoading: (state) => state.addresses.loading,
      cardLoading: (state) => state.cards.loading,
      coupon: (state) => state.coupons.coupon,
      loading: (state) => state.addresses.loading,
      authLoading: (state) => state.auth.loading,
      orderLoading: (state) => state.orders.loading,
      orderErros: (state) => state.orders.errors,
      errors: (state) => state.addresses.errors,
      tables: state => state.tables.tables,
    }),
    scheduledDateTimeRules() {
      if (this.theme.only_scheduled_orders && !this.theme.only_date_for_scheduling) {
        return [(v) => !!v || 'O agendamento é obrigatório'];
      }
      return [];
    },
    formattedScheduledDateTime() {
      if (this.scheduledDateTime) {
        const date = parseISO(this.scheduledDateTime);
        return this.onlyDateForScheduling
          ? format(date, 'dd/MM/yyyy', { locale: ptBR })
          : format(date, 'dd/MM/yyyy HH:mm', { locale: ptBR });
      }
      return '';
    },
  },
  async mounted() {
    this.salesman_code = this.$route?.query?.seller ?? null;
    this.indicator_code = this.$route?.query?.indicator ?? null;

    if (this?.user && (!this?.user?.current_address[0] ?? false)) {
      this.newAddress = true;
    }

    if (this?.theme?.online_payment) {
      this.paymentMode = null;
    }

    if (this.table) {
      this.paymentMode = 'on_delivery';
    }

    if (
      this?.theme?.district_tax &&
      (this?.user?.current_address[0] ?? false) &&
      this?.user?.current_address[0]?.tax
    ) {
      this.updateTax(this?.user?.current_address[0]?.tax);
    }

    if (!this?.theme?.form?.show_email_field && !this?.user) {
      this.showNextStep = false;
    }

    this.onlyDateForScheduling = this.theme.only_date_for_scheduling || false;

    if (this.user?.profile === 'bartender') {
      await this.getTables({ search: null });
    }
  },
  components: {
    AddressForm,
    PriceSummary,
    WithdrawOption,
    CurrentCard,
    CurrentAddressCard,
    CopyToClipboard,
    AddressBottomSheet,
    CardCvvSheet,
    CardBottomSheet,
    CustomerForm,
    PageTitle,
    VDatetimePicker,
  },
  methods: {
    ...mapActions(["storeOrder", "login", "getAddresses", "getCards", "getAuthenticatedUser", "getTables"]),
    async openSheet() {
      this.sheet = true;

      if (this.addresses.length == 0) {
        await this.getAddresses({ search: null });
      }
    },
    async openCardSheet() {
      this.cardSheet = true;

      if (this.cards.length == 0) {
        await this.getCards({ search: null });
      }
    },
    updateTax(newTax) {
      this.newTax = newTax;
    },
    goTo(to) {
      if (this.$route.path === to) return;

      if (to === "sair") {
        this.logout();
        return;
      }

      this.$router.push(to);
    },
    changedPaymentMethod() {
      let notAlloweds = this.theme?.not_allowed_payment_methods_for_promotions;

      if (notAlloweds.length == 0) {
        return false;
      }

      this.withoutPromotion = false;

      if (notAlloweds.includes(this.selectedPaymentMethod)) {
        this.withoutPromotion = true;
        this.$toast.warning(
          "Ao selecionar esse meio de pagamento, os descontos de promocão não serão aplicados. Confira os valores atualizados na tela de resumo."
        )
      }

      this.$store.commit('setSummary', {
        total: this?.total,
        tax: this?.tax,
        cashbackToUse: this?.summary?.cashbackToUse,
        coupon: this?.coupon
      });
    },
    async createOrder() {
      if (this.checkoutLoading) {
        return false;
      }

      let isOnlineCreditCardPayment = this.paymentMode == 'online' && this.selectedPaymentMethod == 'credit_card';

      if (isOnlineCreditCardPayment && !this.cardCvv) {
        this.$toast.info("Informe o CVV do cartão");
        this.cvvSheet = true;
        return false;
      }

      this.checkoutLoading = true;

      if (this.theme.closed) {
        this.$toast.error("O estabelecimento se encontra fechado");
        return false;
      }

      let form = {
        products: this.bagProducts.map(function (product) {
          return {
            product_id: product.id,
            quantity: product.quantity,
            observation: product.observations,
            composition: product.compositions,
          };
        }),
        coupon_id: this?.coupon?.id,
        payment_method: this.selectedPaymentMethod,
        change_value: this.change_value ?? null,
      };

      if (this.user) {
        form = {
          address_id: this?.user?.current_address[0]?.id ?? null,
          withdraw: this.withdraw,
          ...form,
        };

        if (isOnlineCreditCardPayment && (this?.user?.current_address[0]?.id ?? false)) {
          form.card_id = this?.user?.current_card[0]?.id ?? null;
        }

        if (this.newAddress) {
          delete form.address_id;
          form.address = this.address;
        }
      } else {
        form = {
          address: this.address,
          customer: this.customer,
          withdraw: this.withdraw,
          ...form,
        };
      }

      if (this.summary?.cashbackToUse) {
        form.cashback = this.summary?.cashbackToUse;
      }

      if (this.salesman_code) {
        form.salesman_code = this.salesman_code;
      }

      if (this.indicator_code) {
        form.indicator_code = this.indicator_code;
      }

      if (this?.observations) {
        form.observations = this.observations;
      }

      if (this?.cardCvv) {
        form.card_cvv = this?.cardCvv;
      }

      if (this?.table) {
        form.table_id = this?.table?.id;
        form.withdraw = 'table';
      }
      
      form.online_payment = this?.paymentMode == 'online' ? true : false;

      if (this.theme.allow_scheduling) {
        form.scheduled_datetime = this.scheduledDateTime;
      }

      if (this.user?.profile === 'bartender') {
        if (!this.selectedTable) {
          this.$toast.error("Por favor, selecione uma mesa");
          this.checkoutLoading = false;
          return false;
        }

        form.table_id = this.selectedTable;
        form.customer = {
          name: this.customerName ? this.customerName : null,
          phone: this.customerPhone ? this.customerPhone : null,
        };
      }

      try {
        let totalFinal =
          this.total +
          this.tax -
          this.getCouponDiscount(this.coupon, this.total + this.tax);
        let order = await this.storeOrder({ form });

        if (!this.user) {
          await this.login({
            email: this.customer.email,
            phone: this.customer.phone,
            password: this.customer.password,
          });
        } else {
          await this.getAuthenticatedUser();
        }

        this.$toast.success("Pedido realizado com sucesso!");
        this.$store.commit("setBag", { bag: [] });
        
        this.goTo(`/pedidos/${order.id}`);
        
        // fbq('track', 'purchase', {
        //     value: (totalFinal / 100),
        //     currency: 'BRL'
        // });

        if (!this?.user?.current_address[0] && order?.address && this.newAddress) {
          this.$store.commit('setCurrentAddress', {
            address: [order?.address]
          });
        }
      } catch (error) {
        let message = error?.response?.data?.message;
        let firstErrorKey = Object.keys(error?.response?.data)[0] ?? null;

        if (firstErrorKey) {
          message = error?.response?.data[firstErrorKey][0] ?? null;
        }

        this.$toast.error(
          message ??
            "Houve um erro ao processar os dados, verifique os dados informados e tente novamente"
        );
      } finally {
        this.cardCvv = null;
        this.checkoutLoading = false;
      }
    },
    openScheduleDialog() {
      if (this.scheduledDateTime) {
        this.cancelSchedule();
      } else {
        this.tempScheduledDateTime = this.scheduledDateTime;
        if (this.onlyDateForScheduling) {
          this.tempScheduledDateTime = new Date().toISOString().substr(0, 10);
        }
        this.scheduleDialog = true;
      }
    },
    closeScheduleDialog() {
      this.scheduleDialog = false;
      this.tempScheduledDateTime = null;
    },
    confirmSchedule() {
      this.scheduledDateTime = this.tempScheduledDateTime;
      this.closeScheduleDialog();
    },
    cancelSchedule() {
      this.scheduledDateTime = null;
      this.tempScheduledDateTime = null;
    },
  },
};
</script>
