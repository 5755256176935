<template>
	<v-card outlined class="card-product-grid"
		@click="openProductModal()"
	>
		<div class="d-flex flex-column justify-space-between card-content">
			<div style="overflow: hidden; position: relative;">
				<v-img 
					class="image-product"
					lazy-src="@/assets/product-image-placeholder.png" :src="product.imagem"
					cover
				/>
				<div v-if="!isAvailableOnStock(product)" class="ribbon ribbon-top-left ribbon-secondary">
					<span>Indisponível</span>
				</div>
				<div v-else-if="product.free_rate_quantity" class="ribbon ribbon-top-left ribbon-blue">
					<span>Frete grátis</span>
				</div>
				<v-chip v-if="product.has_promotions" class="promotion green lighten-1" x-small>
					PROMOÇÃO
				</v-chip>
			</div>
			<div class="d-flex flex-column justify-space-between mt-3">
				<div style="line-height: 1rem; height: 50px;">
					<span class="font-weight-medium product-name">{{ product.name }}</span>
					<div class="my-1 tags" style="height: 45px; overflow-y: auto; font-size: 11px;">
						<span>{{ product.tags ? product.tags.join(', ') : 'tag' }}</span>
					</div>
				</div>

				<div class="d-flex flex-column justify-end">
					<div class="my-3 price-container">
						<div v-if="(product.promotions[0] ?? null) && product.promotions[0].compositions.length == 0">
							<div class="price-wrapper">
								<div class="original-price">
									<span class="gray--text text--lighten-3">
										De: <s>{{ formatMoney(getProductPrice(product)) }}</s>
									</span>
									<v-chip
										v-if="product.promotions[0].discount_type !== 'fixed'"
										class="discount-chip"
										color="success" 
										x-small
									>
										-{{ product.promotions[0].discount_value / 100 }}%
									</v-chip>
								</div>
								<div class="final-price">
									<span class="primary--text font-weight-medium">
										Por: {{ formatMoney(getProductPrice(product) - getPromotionDiscount(product.promotions[0], product.price)) }}
									</span>
								</div>
							</div>
						</div>
						<div v-else class="single-price">
							<span class="primary--text font-weight-medium">{{ formatMoney(getProductPrice(product)) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
export default {
	name: "ProductGridCard",
	props: ['product'],
	methods: {
		goToProduct(productId) {
			this.$router.push(`produto/${productId}`);
		},
        openProductModal() {
			if (!this.isAvailableOnStock(this.product)) {
				this.$toast.info('Produto indisponível no estoque');
				return false;
			}

            this.$emit('open-dialog', this.product);
        }
	}
}
</script>

<style scoped>
.card-product-grid {
	border-radius: 15px !important;
	height: 100%;
	overflow: hidden;
	border: 1px solid #eee !important;
	width: 100% !important;
	margin: 0 !important;
	transform: none !important;
}

.card-content {
	padding: 12px !important;
}

.image-product {
	border-radius: 10px;
	height: 235px;
	width: 235px;
}

@media (min-width: 768px) {
	.card-product-grid {
		width: 265px !important;
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (min-width: 1904px) {
	.card-product-grid {
		width: 251px !important;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.card-product-grid:hover {
	border-color: #bbb !important;
}
.product-name {
	font-size: 0.95rem;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.tags {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.ribbon {
	width: 150px;
	height: 100px;
	overflow: hidden;
	position: absolute;
}

.ribbon::before,
.ribbon::after {
	position: absolute;
	z-index: -1;
	content: '';
	display: block;
	border: 5px solid #2980b9;
}

.ribbon span {
	position: absolute;
	display: block;
	width: 200px;
	padding: 10px 0;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
	color: #fff;
	font: 700 18px/1 'Lato', sans-serif;
	font-size: 8px;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
	text-transform: uppercase;
	text-align: center;
}

.ribbon-red span {
	background-color: #DD1C1E;
}

.ribbon-blue span {
	background-color: #2980b9;
}

.ribbon-secondary span {
	background-color: #A6A6A6;
}

/* top left*/
.ribbon-top-left {
	top: -15px;
	left: -20px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
	border-top-color: transparent;
	border-left-color: transparent;
}

.ribbon-top-left::before {
	top: 0;
	right: 0;
}

.ribbon-top-left::after {
	bottom: 0;
	left: 0;
}

.ribbon-top-left span {
	right: -15px;
	top: 25px;
	height: 0px;
	padding-bottom: 18px;
	padding-top: 7px;
	padding-right: 20px;
	transform: rotate(-45deg);
}

.promotion {
	color: #fff !important;
	position: absolute;
	bottom: 10px !important;
}

.product-grid-card {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 300px;
	width: 100%;
}

@media (max-width: 768px) {
	.card-product-grid {
		width: 160px !important;
		min-width: 160px !important;
		margin: 0 4px !important;
		height: auto !important;
	}

	.card-content {
		padding: 8px !important;
	}

	.image-product {
		height: 160px !important;
		width: 160px !important;
		object-fit: cover;
	}

	.product-name {
		font-size: 0.85rem;
		line-height: 1.2;
		height: auto;
		margin-bottom: 4px;
	}

	.tags {
		font-size: 10px;
		height: 32px !important;
		margin: 4px 0 !important;
	}

	.primary--text {
		font-size: 1rem !important;
	}

	.original-price {
		font-size: 12px;
		gap: 4px;
	}

	.final-price, .single-price {
		font-size: 1.1rem;
	}

	.discount-chip {
		height: 16px !important;
		font-size: 10px !important;
	}

	.price-wrapper {
		gap: 2px;
	}
}

.price-container {
    line-height: 1.2;
}

.price-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.original-price {
    display: flex;
    align-items: center;
	justify-content: center;
    gap: 8px;
    font-size: 14px;
}

.discount-chip {
    margin: 0 !important;
}

.final-price {
    font-size: 1.3rem;
}

.single-price {
    font-size: 1.3rem;
}
</style>