<template>
  <div
    class="notranslate"
    translate="no"
  >
    <v-list>
      <v-list-item-group
        v-for="(composition, i) in compositions"
        v-model="list[composition.name]"
        :multiple="composition.max_selection > 1"
        :max="composition.max_selection > 1 ? composition.max_selection : null"
        :key="`subheader-${i}`"
        :id="composition.name.toLowerCase()"
      >
        <template>
          <div >
            <v-subheader class="px-0">
              <div 
                class="primary--text d-flex items-center justify-space-between blue-grey lighten-5 py-1 px-3 text-capitalize"
                style="font-size: 14px; margin-bottom: -20px; width: 100%;">
                  <span class="font-weight-bold text-capitalize">
                    {{ composition.name }}
                    <span class="caption gray--text" v-if="composition.min_selection && composition.min_selection > 1"> ({{ `Min. ${composition.min_selection}`}})</span>
                    <span class="caption gray--text" v-if="composition.max_selection && composition.max_selection > 1"> ({{ `Máx. ${composition.max_selection}`}})</span>
                  </span>
                  <span class="caption gray--text" v-if="composition.min_selection && composition.min_selection < 1 || !composition.min_selection">
                    <v-chip x-small outlined class="ml-1" text-color="green">
                      opcional
                    </v-chip>
                  </span>
                  <span class="caption gray--text" v-if="composition.min_selection && composition.min_selection > 0">
                    <v-chip x-small outlined class="ml-1" text-color="green">
                      obrigatório
                    </v-chip>
                  </span>
                  <span class="caption gray--text" v-if="composition?.use_max_value_as_price && (composition.max_selection && composition.max_selection > 1)">
                    <v-chip x-small outlined class="ml-1" text-color="info">
                      maior valor será considerado final
                    </v-chip>
                  </span>
              </div>
            </v-subheader>
          </div>
          <v-list-item
            color="primary"
            v-for="(item, j) in composition.options"
            :key="`${item.name}-${j}`"
            :value="item"
            active-class="primary--text transparent text--accent-4"
            class="py-0"
          >
            <template v-slot:default="{ active, toggle }">
              <v-list-item-content @click="goToNextList(composition, compositions[i + 1] ?? null, active)">
                <v-list-item-title>
                  <div v-if="!item?.description">
                    <span>{{ item.name }}</span>
                    <span class="caption" v-if="item.additional_price > 0">
                      ({{ `${composition?.use_max_value_as_price ? '' : '+ '}${formatMoney(item.additional_price)}` }})
                    </span>
                  </div>
                  <div class="d-flex flex-column" style="font-size: 14px;" v-else>
                    <div>
                      <span>{{ item.name }}</span>
                      <span class="caption" v-if="item.additional_price > 0">
                        ({{ `${composition?.use_max_value_as_price ? '' : '+ '}${formatMoney(item.additional_price)}` }})
                      </span>
                    </div>
                    <div>
                      <span class="caption">{{ item.description }}</span>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <!-- <v-radio
                  class="mb-0"
                  :value="item"
                ></v-radio> -->
                <v-checkbox
                  :input-value="active"
                  :true-value="item"
                  :disabled="canSelect(composition, item, active)"
                  color="primary"
                  @click="toggle, goToNextList(composition, compositions[i + 1] ?? null, active)"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  export default {
    name: 'composition',
    props: ['compositions', 'model', 'promotions'],
    computed: {
      list: {
        get() {
          return this.model;
        },
        set(newValue, oldValue) {
          if (newValue !== oldValue) this.$emit('update:model', newValue);
        }
      }
    },
    methods: {
      canSelect(composition, item, active) {
        let currentSelectedOptions = this.model[composition.name];
        let totalSelected = 0;

        if (currentSelectedOptions instanceof Array) {
          totalSelected = currentSelectedOptions.length;
        } else {
          totalSelected = 1;
        }
        
        if (
          totalSelected == composition?.max_selection ||
          !composition?.max_selection &&
          !active
        ) {
          return true;
        }
      },
      goToNextList(composition, nextComposition, active) {
        let currentSelectedOptions = this.model[composition.name];
        let totalSelections = (currentSelectedOptions?.length ?? 0) + (active ? -1 : 1);
        
        if (
          totalSelections == composition?.max_selection ||
          !composition?.max_selection
        ) {
          if (nextComposition) {
            document.getElementById(nextComposition.name.toLowerCase()).scrollIntoView({
              behavior: 'smooth'
            });
          } else {
            document.getElementById('observation').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }
      },
      isPromotional(composition, option) {
        if (!this.promotions) {
          return false;
        }

        let promotionFinded = null;

        for (let index in this.promotions) {
          let promotion = this.promotions[index];
          
          promotionFinded = promotion.compositions.find(
            (promotionComposition) => promotionComposition.name == composition && promotionComposition.option == option
          );

          if (promotionFinded) {
            return true;
          }
        }

        return false;
      }
    }
  }
</script>