<template>
  <v-card
    :class="['address-card', { 'current-address': current && current.id == address.id }]"
    :elevation="current && current.id == address.id ? 4 : 1"
    rounded="lg"
  >   
    <v-card-text>
      <v-row no-gutters @click="toggleAddress">
        <v-col cols="12" sm="9">
          <h3 class="text-subtitle-1 font-weight-medium mb-2">Endereço de entrega</h3>
          <p class="mb-1">
            {{ address?.street }}, {{ address?.number }}
            <!-- TODO: Adicionar referência -->
          </p>
          <p class="mb-1">
            <span v-if="address?.district">{{ address?.district }}</span>
            <span v-if="address?.city"> - {{ address?.city }}</span>
            <span>{{ address?.state }}</span>
          </p>
          <p class="text-caption mt-2">
            <v-icon small class="mr-1">mdi-tag</v-icon>
            Identificador: {{ address?.name }}
          </p>
          <p v-if="theme?.district_tax && address?.tax > 0" class="text-caption">
            <v-icon small class="mr-1">mdi-truck-delivery</v-icon>
            Taxa de entrega {{ formatMoney(address?.tax) }}
          </p>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex flex-column justify-space-between align-end">
          <v-radio
            ref="radioBtn"
            :value="address.id"
            class="mt-0 mb-2"
          ></v-radio>
          <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in items" :key="i" @click.stop="item.action">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    name: 'addressCard',
    props: ['address', 'current', 'theme'],
    data() {
        return {
            items: [
                { title: "Editar", action: this.edit},
                { title: "Excluir", action: this.destroy}
            ]
        }
    },
    methods: {
        toggleAddress() {
            let elem = this.$refs.radioBtn.$el;
            elem.click();
        },
        edit() {
            this.$router.push('/endereco/' + this.address.id + '/editar');
        },
        destroy() {
            this.$emit('destroy', this.address.id);
        },
        setAddress(address) {
            this.$emit('settedAddress', address);
        },
    }
}
</script>

<style scoped>
.address-card {
  transition: all 0.3s ease;
}

.current-address {
  background-color: #FFF3E0 !important;
  border-left: 4px solid #FF9800;
}

.address-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.v-card__text {
  padding: 20px;
}

h3 {
  color: #424242;
}

p {
  margin-bottom: 4px;
  color: #616161;
}

.text-caption {
  color: #757575;
}
</style>
