<template>
  <div style="padding-bottom: 80px;">
    <div class="py-0 pt-0 d-flex justify-center text-center">
      <v-container class="pa-0" style="position: relative;">
        <div class="banner-cover" :style="`${theme?.cover ? 'background: url(' + theme?.cover + ');' : ''}`"></div>
        <div
          style="border-radius: 0px 0px 20px 20px; background: linear-gradient(359.85deg, #000000 -60%, rgba(0, 0, 0, 0)); height: 100%; width: 100%; top: 0; position: absolute;">
        </div>
        <v-chip
            style="position: absolute; top: 0; right: 0;"
            @click="openSchedules"
            class="store-status ma-3"
            :color="theme?.closed ? 'light' : 'success'"
            small
          >
            <v-icon left size="18">
              mdi-storefront-outline
            </v-icon>
            {{ theme?.closed ? "Fechado" : "Aberto" }}
          </v-chip>
      </v-container>
    </div>

    <v-container>
      <div class="d-flex company-info">
        <div class="logo-container">
          <v-img :src="theme.logo" :lazy-src="theme.logo" class="logo" contain></v-img>
        </div>
        <company-name :theme="theme" :show="true"></company-name>
        <div class="see-more gray--text text--lighten-5 ml-2" @click="openSchedules">
          Ver mais
        </div>
      </div>
    </v-container>

    <v-container>
      <v-row class="mb-4">
        <v-col cols="12" sm="6" md="4" v-if="theme.free_rate_from && theme.free_rate_from > 0">
          <div class="d-flex align-center mb-1 rounded-lg green" style="padding: 10px; color: #fff !important;">
            <v-icon color="white" size="20" class="mr-1">mdi-truck-delivery</v-icon>
            <span>Frete Grátis a partir de R$ {{ (theme.free_rate_from / 100).toFixed(2) }}</span>
          </div>
        </v-col>
        
        <v-col cols="12" sm="6" md="4" v-if="theme?.active_cashback && theme?.cashback_settings?.cashback_by_total_amount && theme?.cashback_settings?.cashback_rules">
          <cashback-rules-dialog :cashbackRules="theme?.cashback_settings?.cashback_rules" />
        </v-col>
      </v-row>
      <schedule v-model="openSchedule" :closed="theme?.closed" :schedule="theme?.schedule">
      </schedule>
      <v-text-field label="Pesquisar" ref="searchField" class="mt-2" append-icon="mdi-magnify" v-model="search" @input="searchProducts"
        dense single-line rounded outlined hint="Digite o nome do produto que procura" />
      <v-layout v-if="categoriesLoading" class="text-center d-flex flex-column align-start">
        <span class="font-weight-bold text-uppercase">Encontre o que procura</span>
        <chip-skeleton></chip-skeleton>
      </v-layout>
      <div v-else>
        <div class="mb-4">
          <span class="font-weight-bold text-uppercase">Encontre o que procura</span>
          <v-chip-group mandatory class="category-filter">
            <v-chip class="ma-2 ml-0" v-for="(category, i) in categories" :key="i"
              :color="category.id === selectedCategory?.id ? 'primary' : ''" @click="setCategory(category)">
              {{ category.name }}
              <v-avatar v-if="category.id === selectedCategory?.id">
                <v-icon>mdi-filter</v-icon>
              </v-avatar>
            </v-chip>
          </v-chip-group>
        </div>
        <div v-if="selectedCategory" class="my-2">
          <span class="font-weight-bold">Filtrando por:</span>
          {{ selectedCategory.name }}
        </div>
      </div>
      <div v-if="this.search === '' && selectedCategory == ''">
        <div v-if="highlights.length > 0 || highlightsLoading">
          <span class="font-weight-bold text-uppercase">Mais pedidos</span>
          <v-layout>
            <highlights-skeleton v-if="highlightsLoading" :products="highlights"></highlights-skeleton>
            <highlights v-else-if="highlights" :products="highlights" @open-dialog="openDialog"></highlights>
          </v-layout>
        </div>
      </div>
      <div class="p-list">
        <div 
          v-infinite-scroll="loadMoreProduct" 
          infinite-scroll-disabled="busy"
          :infinite-scroll-distance="products.data.length * 100"
        >
          <div 
            class="mt-6 product-list" 
            v-for="(products, category_name) in groupedProduct" 
            :key="category_name"
          >
            <product-list 
              :products="products"
              :theme="theme"
              :category="category_name" 
              :isFiltered="!!selectedCategory"
              @categoryFilter="setCategory"
              @open-dialog="openDialog"
            />
          </div>
        </div>
        <v-layout v-if="loading" class="text-center mt-5">
          <product-card-skeleton :theme="theme" />
        </v-layout>
        <div v-if="!loading && products.data.length == 0" class="text-center">
          <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
          <span class="font-weight-bold">Nenhum resultado encontrado</span>
        </div>
      </div>
    </v-container>
    <div class="p-list-mobile">
      <div 
        v-infinite-scroll="loadMoreProduct" 
        infinite-scroll-disabled="busy"
        :infinite-scroll-distance="products.data.length * 100"
      >
        <div class="mt-6 white product-list" v-for="(products, category_name) in groupedProduct" :key="category_name">
          <product-list
            :products="products"
            :theme="theme"
            :category="category_name"
            :isFiltered="!!selectedCategory"
            @categoryFilter="setCategory"
            @open-dialog="openDialog" />
        </div>
      </div>
      <v-layout v-if="loading" class="text-center mt-5">
        <product-card-skeleton :theme="theme" />
      </v-layout>
      <div v-if="!loading && products.data.length == 0" class="text-center">
        <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
        <span class="font-weight-bold">Nenhum resultado encontrado</span>
      </div>
    </div>

    <!-- Adicione o diálogo do produto aqui -->
    <div class="modal-product-mobile">
      <v-dialog v-if="$vuetify.breakpoint.smAndDown" v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <product-dialog ref="productDialog" :product="selectedProduct" @close-dialog="closeDialog" />
      </v-dialog>
      <v-dialog v-if="$vuetify.breakpoint.smAndUp" transition="dialog-bottom-transition" v-model="dialog" :width="1200">
        <product-dialog ref="productDialog" :product="selectedProduct" @close-dialog="closeDialog" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TextField from "../components/inputs/TextField";
import ChipSkeleton from "../components/skeletons/Chips";
import Schedule from "../components/Schedule";
import CompanyName from "../components/CompanyName";
import ProductCardSkeleton from "../components/skeletons/ProductCard";
import HighlightsSkeleton from "../components/skeletons/Highlights";
import CashbackRulesDialog from "../components/modals/CashbackRulesDialog";
import ProductList from "../components/ProductList";
import Highlights from "../components/slides/Highlights";
import ProductDialog from "../components/ProductDialog";
import _debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",

  components: {
    TextField,
    CompanyName,
    ProductList,
    ChipSkeleton,
    ProductCardSkeleton,
    Schedule,
    CashbackRulesDialog,
    HighlightsSkeleton,
    Highlights,
    ProductDialog,
  },

  async created() {
    if (this.categories.length == 0) {
      this.getCategories({});
    }

    if (this.highlights.length == 0) {
      this.getHighlights({});
    }

    if (this.products.data.length == 0) {
      this.getProducts({});
    }

    this.requestPermission();
  },

  data() {
    return {
      search: "",
      busy: false,
      scheduleDialog: false,
      selectedCategory: "",
      expandedCategories: {},
      dialog: false,
      selectedProduct: null,
    };
  },

  computed: {
    ...mapState({
      openSchedule: (state) => state.theme.openSchedule,
      theme: (state) => state.theme.theme,
      categories: (state) => state.categories.categories,
      categoriesLoading: (state) => state.categories.loading,
      products: (state) => state.products.products,
      highlights: (state) => state.products.highlights,
      loading: (state) => state.products.loading,
      highlightsLoading: (state) => state.products.highlightsLoading,
    }),
    groupedProduct() {
      return this.products.data.reduce((group, product) => {
        let { category_name } = product;
        group[category_name] = group[category_name] ?? [];
        group[category_name].push(product);
        return group;
      }, {});
    },
  },

  methods: {
    ...mapActions(["getProducts", "getCategories", "getHighlights"]),

    searchProducts: _debounce(async function () {
      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
      });
    }, 600),
    // showNotificationPermissionRequest() {
    //   if (Notification.permission === 'default') {
    //     var options = {
    //       body: 'Seja bem-vindo ao <nome do site>! Para aprimorar sua experiência e receber notificações de promoções e descontos, clique no botão "Eu quero".',
    //       icon: 'caminho/para/icone.png',
    //       requireInteraction: true,
    //       actions: [
    //         { action: 'confirmar', title: 'Eu quero' }
    //       ]
    //     };

    //     var notification = new Notification('Permissão de Notificação', options);

    //     notification.addEventListener('notificationclick', function(event) {
    //       if (event.action === 'confirmar') {

    //       }

    //       // notification.close();
    //     });
    //   }
    // },
    requestPermission() {
      if ("Notification" in window && navigator.serviceWorker) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // this.showNotificationPermissionRequest();
          } else if (permission === "denied") {
          } else {
          }
        });
      }
    },

    async loadMoreProduct() {
      let cantLoadMore =
        this?.products.data.length >= this?.products?.meta?.total;

      if (this.busy || this.loading || cantLoadMore) return;

      this.busy = true;

      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
        page: this?.products?.meta?.current_page + 1,
      });

      this.busy = false;
    },

    async setCategory(category) {
      if (typeof category === 'string') {
        category = this.categories.find(c => c.name === category);
      }

      this.selectedCategory =
        this.selectedCategory?.id === category.id ? "" : category;

      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
      });
    },
    openSchedules() {
      this.$store.commit('setOpenSchedule', {
        openSchedule: true
      });
    },

    openDialog(product) {
      if (this.$vuetify.breakpoint.smAndDown) {
        // Para telas menores, navegue para a página do produto
        this.$router.push(`/produto/${product.id}`);
      } else {
        // Para telas maiores, abra o modal
        this.dialog = true;
        this.selectedProduct = product;
      }
    },

    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style>
.banner-cover {
  width: 100%;
  min-height: 150px;
  background-size: cover !important;
  background-color: var(--v-secondary);
}

.company-info {
  margin-top: -44px;
  position: relative;
}

.logo-container {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--v-primary-base);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.see-more {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
  min-width: 60px !important;
}

.logo {
  object-fit: cover;
  object-position: center;
  height: 100px;
  width: 100px
}

.logo-position {
  transform: translate(15px, 85px);
  width: 90%;
}

.p-list {
  display: none;
}

.p-list-mobile {
  display: block;
}

@media (min-width: 768px) {
  .p-list {
    display: block;
  }

  .p-list-mobile {
    display: none;
  }
  
  .company-info {
    margin-top: -57px;
    position: relative;
  }

  .product-list {
    border-radius: 10px;
  }

  .logo-box {
    min-width: 90px !important;
    max-width: 90px !important;
    min-height: 90px !important;
    max-height: 90px !important;
  }

  .see-more {
    margin-top: 18px;
  }

  .banner-cover {
    min-height: 250px;
    border-radius: 0px 0px 20px 20px;
  }

  .page-top-margin {
    margin-top: 60px !important;
  }

  .logo-position {
    transform: translate(25px, 80px);
  }

  .company-description-without-background {
    margin-top: -5px;
    margin-left: 97px;
  }
}

.banner {
  padding-top: 20px;
  background: #fff;
  height: 100px;
  border-radius: 300% 300% 0px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.product-list {
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.free-shipping-card {
  border-left: 4px solid var(--v-primary-base);
  background-color: var(--v-primary-lighten5);
  transition: all 0.3s ease;
}

.free-shipping-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.free-shipping-card,
.cashback-card {
  height: 100%;
}

@media (max-width: 599px) {
  .free-shipping-card,
  .cashback-card {
    margin-bottom: 16px;
  }
}

.v-expansion-panel-header {
  font-weight: bold;
  text-transform: uppercase;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.store-status {
  cursor: pointer !important;
  position: fixed !important;
  z-index: 1000;

  @media (min-width: 768px) {
    position: absolute !important;
    z-index: 1 !important;
    top: 0 !important;
    right: 0 !important;
  }
}

.modal-product-mobile {
  /* display: block; */
}

.text-body-2 {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 600px) {
  .text-body-2 {
    font-size: 0.8125rem;
  }
}
</style>





