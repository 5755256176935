<template>
  <v-app :style="cssProps">
    <vue-splash
      :style="`${theme?.logoBackground ? 'color: #fff;' : ''}`"
      :show="theme && loadingSplash"
      :logo="theme ? theme.logo : 'https://meodelivery.s3.amazonaws.com/Ativo+4meo_delivery.png'"
      :title="theme?.siteName"
      color="#00bfa5"
      :size="150"
      :fixed="true"
      :background-color="theme?.logoBackground ? theme?.themeColor1 : '#fff'"
    />
    <drawer v-if="theme && !loadingSplash">
      <v-main>
        <transition name="page" mode="out-in">
          <content-centered class="grey lighten-4">
            <router-view/>
            <generic-dialog
              :title="table?.name"
              :open="tableDialog"
              @close="tableDialog = false"
            >
              <span>Seja bem vindo 😊</span> <br />
              <span>
                Você está na <span class="font-weight-bold">{{ table?.name }}</span>
              </span> <br />
              <span>Por favor, faça seu pedido e relaxe enquanto nosso atendente se prepara para atendê-lo.</span>
            </generic-dialog>
          </content-centered>
        </transition>
      </v-main>
    </drawer>
    <not-found v-if="!theme && !loadingSplash"/>
    <profile-selection-modal
      v-if="showProfileSelectionModal"
    />
  </v-app>
</template>

<script>

import Drawer from '@/components/navigation/Drawer';
import GenericDialog from '@/components/generics/GenericDialog';
import ContentCentered from './components/layouts/ContentCentered.vue';
import NotFound from './views/404.vue';
// import Favicon from '@/components/Favicon.vue';
import { mapState, mapActions } from 'vuex';
import ProfileSelectionModal from '@/components/modals/ProfileSelectionModal.vue';

export default {
  name: 'App',

  components: {
    ContentCentered,
    NotFound,
    GenericDialog,
    Drawer,
    ProfileSelectionModal
  },

  async created() {
    if (!this.theme) {
      this.loadingSplash = true;
      await this.getTheme();
      this.loadingSplash = false;
    }

    if (this.theme) {
      this.setThemeColor(this.theme);
      document.title = this.theme?.siteName;
      let tabCode = this.$route?.query?.tab ?? null;

      let element = document.querySelector('#manifest-placeholder');
      var link = document.querySelector("link[rel~='icon']");
      let ogTitle = document.querySelector('meta[property="og:title"]');
      let ogImage = document.querySelector('meta[property="og:image"]');
      let ogUrl = document.querySelector('meta[property="og:url"]');

      if (tabCode) {
        await this.findTable({ tableCode: tabCode});

        if (this.table) {
          this.tableDialog = true;
        }
      }

      ogTitle.content = this.theme?.siteName;

      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = this.theme.favico;

      element.setAttribute('href', this.theme?.manifest);

      if (this.theme?.facebook_pixel) {
        const pixelScript = document.createElement('script');

        // Set the script content
        pixelScript.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${this.theme?.facebook_pixel}');
          fbq('track', 'PageView');
        `;

        // Insert the script into the <head> of the webpage
        document.head.appendChild(pixelScript);
      }
    }
  },

  async mounted() {
    if (this.user) {
      await this.getAuthenticatedUser();
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (!localStorage.getItem('profile') && this.user?.bartender_id) {
          this.showProfileSelectionModal = true;
        }
      }
    }
  },

  data: () => ({
    loadingSplash: false,
    tableDialog: false,
    showProfileSelectionModal: false
  }),

  computed: {
    ...mapState({
      theme: state => state.theme.theme,
      table: state => state.tables.table,
      user: state => state.auth.user,
      profile: state => state.auth.profile
    }),
    cssProps () {
      var themeColors = {}
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
      })
      
      return themeColors
    }
  },
  methods: {
    ...mapActions(["findTable", "getTheme", "getAuthenticatedUser", "setUserProfile", "setCurrentProfile"]),
    setThemeColor(theme) {
      this.$vuetify.theme.themes.light.primary = theme.themeColor1;
      this.$vuetify.theme.themes.light.secondary = theme.themeColor2;
    },

    checkUserProfile(user) {
      if (user && user.bartender_id !== null && !this.userProfile) {
        this.showProfileSelectionModal = true;
      }
    },
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, #app {
  font-family: 'Montserrat', sans-serif;
}

* {
  font-family: inherit;
  box-sizing: inherit;
}

.logo-background {
  background: var(--v-primary);
  border-radius: 50%;
}

/* Estilos para a transição de página */
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.page-enter-to,
.page-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
